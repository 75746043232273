<template>
  <div class="container">
    <div class="foot-line">
    </div>
    <div class="foot">
        <img
          class="foot-mobile__logo d_none"
          src="@/assets/images/logo.jpg "
          alt="logo"
          @click="toMain"
        />
        <div class="foot-left__card">
          <p class="text-top px16-500">
            {{ $t("signEmail") }}
          </p>
          <div class="input1">
            <input v-model="v$.email.$model" type="text" :placeholder="$t('writeEmail')" />
            <img @click="sendMail" src="@/assets/images/submit_btn.png" alt="" />
          </div>
        </div>
        <div class="foot-right__card">
          <div class="foot-right__card-images d_none">
            <a target="_blank" href="https://wa.me/77779999845"><img src="@/assets/images/whatsapp_logo.svg" alt="" /></a>
            <a target="_blank" :href="foot.telegram"><img src="@/assets/images/telegram_logo.svg" alt="" /></a>
            <a target="_blank" :href="foot.instagram"><img src="@/assets/images/instagram_logo_footer.svg" alt="" /></a>
            <a target="_blank" :href="foot.vk"><img src="@/assets/images/vk_logo.svg" alt="" /></a>
            <a target="_blank" :href="foot.facebook"><img src="@/assets/images/facebook.svg" alt="" /></a>
          </div>
          <p class="px20-800" style="margin-bottom: 10px;">{{ $t("care") }}</p>
          <div class="nums">
            <a v-for="(item, index) in foot.phone_number" :key="index" class="px16-400 price" :href="'tel:' + item" v-html="item"></a>
            <!-- <a class="px16-400 price" :href="'tel:' + foot.phone_number" v-html="foot.phone_number"></a> -->
          </div>
          <div class="foot-right__card-images m_none">
            <div class="line">
              <a target="_blank" href="https://wa.me/77779999845"><img src="@/assets/images/whatsapp_logo.svg" alt="" /></a>
              <a target="_blank" :href="foot.telegram"><img src="@/assets/images/telegram_logo.svg" alt="" /></a>
            </div>
            <div class="line">
              <a target="_blank" :href="foot.instagram"><img src="@/assets/images/instagram_logo_footer.svg" alt="" /></a>
              <a target="_blank" :href="foot.vk"><img src="@/assets/images/vk_logo.svg" alt="" /></a>
              <a target="_blank" :href="foot.facebook"><img src="@/assets/images/facebook.svg" alt="" /></a>
            </div>
          </div>
        </div>
        <div class="foot-nav">
          <p style="text-transform: uppercase;" class="px20-800">{{ $t("customer") }}</p>
          <ul>
            <li><router-link class="px16-400" to="/catalog/palto-vesna-osen">{{ $t("catalog") }}</router-link></li>
            <!-- <li><router-link class="px16-400" to="/">{{ $t("size") }}</router-link></li> -->
            <li><router-link class="px16-400" to="/blog">{{ $t("brand") }}</router-link></li>
            <li><router-link class="px16-400" to="/delivery">{{ $t("delivery") }}</router-link></li>
            <li><router-link class="px16-400" to="/contacts">{{ $t("contacts") }}</router-link></li>
          </ul>
        </div>
        <img
            class="foot-left__card-img m_none"
            src="@/assets/images/logo-foot.svg"
            alt=""
            @click="toMain"
          />
    </div>
    <p class="text-btm px10-500 m_none">© “Терi Сарайы”, 2023</p>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";

const toast = useToast();
export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      type: "arrivals",
      foot: ""
    }
  },
  validations() {
      return {
        email: {
            required: helpers.withMessage(this.$t('req'), required),
            email: helpers.withMessage(this.$t('reqMail'), email),
        },
      };
    },
  created() {
    this.getFooter()
  },
  methods: {
    sendMail() {
      this.v$.$validate()
      axios
      .post('mail', {
        email: this.email,
        type: this.type
      })
      .then( res => {
        if(res.status === 200) {
          toast.success(this.$t('sub'))
          this.email = ""
        }
      })
      .catch(err => {
        console.log(err);
        toast.error(this.$t('reqMail'))
      })
    },
    getFooter() {
      axios.get('get-footer')
      .then(response => {
          this.foot = response.data.contacts
      })
      .catch(e=> {
          this.errors.push(e);
      })
    },
    toMain() {
      this.$router.push('/')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/footer.scss";
</style>
