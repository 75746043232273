<template>
  <div class="category">
    <p class="category-title px16 fw600">{{ $t("category") }}</p>
    <div class="category-box m_none" v-if="HEADER.categories">
      <ul class="m_none">
        <li
          v-for="(item, index) in HEADER.categories"
          :key="index"
          class="category-box__li"
        >
          <router-link
            class="px16 fw500 black cat-link"
            @click="activeSub(index)"
            :to="{ path: `/catalog/${item.slug}` }"
            >{{ item.title }}
            <img
              v-if="item.subcategories.length > 0"
              src="@/assets/images/arrow_down.png"
              alt=""
              @click="activeSub"
              :class="{'rotate-arr' : index === isSub }"
            />
          </router-link>
          <Transition>
            <div class="category-sub" v-if="isSub == index">
              <ul>
                <li v-for="(filter, index) in item.subcategories" :key="index">
                  <router-link :to="{ path: `/catalog/${filter.slug}` }">{{
                    filter.title
                  }}</router-link>
                </li>
              </ul>
            </div>
          </Transition>
        </li>
      </ul>
    </div>
    <div class="select d_none" v-if="HEADER.categories">
      <p @click="activeCategory = true">{{ $t("category") }}</p>
      <Transition>
      <div class="select-links" v-show="activeCategory">
        <div class="category-box" v-if="HEADER.categories">
          <div class="category-box__top">
            <img @click="activeCategory = false" src="@/assets/images/close.png" alt="">
            <p class="px16 fw600" style="text-transform: uppercase; margin-left: 15px;">{{ $t("category") }}</p>
          </div>
          <ul class="">
            <li
              v-for="(item, index) in HEADER.categories"
              :key="index"
              class="category-box__li"
            >
              <router-link
                class="px16 fw500 gray"
                @click="activeSub(index)"
                :to="{ path: `/catalog/${item.slug}` }"
                >{{ item.title }}
                <img
                  v-if="item.subcategories.length > 0"
                  src="@/assets/images/arrow_down.png"
                  alt=""
                  @click="activeSub"
                />
              </router-link>
              <div class="category-sub" v-if="isSub == index">
                <ul>
                  <li v-for="(filter, index) in item.subcategories" :key="index">
                    <router-link :to="{ path: `/catalog/${filter.slug}` }">{{
                      filter.title
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Transition>
    </div>
    <div v-if="activeCategory" @click="activeCategory = false" class="visible"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isSub: false,
      activeCategory: false
    };
  },
  mounted() {
    this.GET_HEADER(this.$route.params.slug);
  },
  methods: {
    ...mapActions(["GET_HEADER"]),
    activeSub(index) {
      this.isSub = index;
    },
  },
  computed: {
    ...mapGetters(["HEADER"]),
    ...mapState(["cdn"]),
  },
  watch: {
    $route() {
      this.GET_HEADER(this.$route.params.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.visible {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 110;
}
.rotate-arr {
  transform: rotate(180deg);
}
.category {
  max-width: 360px;
  &-title {
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  &-box {
    border: 1px solid #e4e4e4;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    &__li {
      margin: 10px 15px;
      img {
        transition: all 0.4s;
      }
    }
    &__top {
      p {
        padding: 10px 0 !important;
      }
      img {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
  &-sub {
    margin-left: 10%;
    a {
      color: #000;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
@media (max-width: 970px) {
  .category {
    margin-top: -76px !important;
    &-box {
      border: none;

      &-top {
        padding-bottom: 10rem;
      }
    }
  }
  .select {
    width: 150px;
    height: 60px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    // position: relative;
    p {
      text-align: center;
      padding: 18px;
      color: #000;
    }
    &-links {
      position: absolute;
      top: 30%;
      left: 8px;
      right: 0;
      width: 96%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dcdcdc;
      z-index: 111;
      transition: all 0.4s ease;
      li {
        display: flex;
        flex-direction: column;
        padding: 3px 0;
        a {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
