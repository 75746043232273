import { createApp } from 'vue'
import App from './App.vue'
import VueRouter from './router'
import "@/assets/styles/main.scss"
import axios from 'axios'
import store from './store'
import i18n from './i18n'
import VueTheMask from 'vue-the-mask'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
// const lang = localStorage.getItem('lang') || 'ru';
// const head = createHead()
axios.defaults.baseURL = 'https://admin.terisarayi.kz/api/';
// axios.defaults.params = {
//   'lang': lang,
// };
import { createHead } from "@vueuse/head"

const options = {
    position: "top-center",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false
  };
  const head = createHead()
const app = createApp(App)
app.use(VueRouter)
app.use(head)
app.use(i18n)
app.use(store)
app.use(moment)
app.use(VueTheMask)
app.use(Toast, options)
app.use(VueViewer)
app.mount('#app')
