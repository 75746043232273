<template>
    <div class="" v-if="sub">
        <p class="sub-title px16 fw500">Управление подписками</p>
        <div class="sub-box" v-for="(item, index) in sub.subscriptions" :key="index">
            <p v-if="sub.is_signed = true" class="fw600 px18" style="color: #fff; margin-bottom: 20px;">{{ item.title }}</p>
            <!-- {{ sub.is_signed }} -->
            <!-- <img v-if="sub.is_signed = true" :src="cdn + item.image" alt=""> -->
            <button v-if="sub.is_signed = true" @click="isOpen = true">Подписаться</button>
        </div>
        <Modal :open="isOpen" @close="isOpen = !isOpen">
            <div class="modal">
                <p class="fw600 px18">Введите email для подписки</p>
                <input type="text" v-model="email" placeholder="Email" class="input">
                <button class="button-active" @click="sendMail">Отправить</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import axios from "axios"
import { mapState } from "vuex"
import Modal from "../modal/Modal.vue"
import {ref} from 'vue'
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
    components: { Modal },
    data() {
        return {
            sub: [],
            type: "sales"
        }
    },
    setup() {
        const isOpen = ref(false);
        return {
            isOpen,
        };
    },
    created() {
        this.mySubscribe()
    },
    methods: {
        mySubscribe() {
            axios.get('auth/my-subscriptions', {
                headers: {
                Authorization: localStorage.getItem("access_token")
                    ? `Bearer ${localStorage.getItem("access_token")}`
                    : null,
                },
            })
            .then(response => {
                this.sub = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        sendMail() {
            axios
            .post('mail', {
                email: this.email,
                type: this.type
            })
            .then( res => {
                if(res.status === 200) {
                toast.success("Вы удачно подписались на рассылку!");
                this.isOpen = false
                }
            })
        },
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.sub {
    &-title {
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &-box {
        background: #9E9599;
        padding: 25px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 200px;
            margin: 20px 0;
        }
        button {
            font-weight: 600;
            font-size: 14px; 
            color: #9E9599;
            background: #FFFFFF;
            width: 200px;
            border: none;
            padding: 12px 0;
            transition: 0.4s;
            &:hover {
                transition: 0.4s;
                background-color: #f5eded;
            }
        }
    }
}
.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
        width: 85%;
        margin: 30px 0;
    }
    button {
        width: 200px;
    }
}
@media (max-width: 970px) {
  .sub {
    &-title {
        margin: 60px 0 20px 0;
        font-weight: 600;
    }
        &-box {
            width: 100%;
        }
    }
}
</style>