<template>
    <div class="container">
        <nav-bar/>
        <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
        <div class="contact" v-if="foot">
            <div class="contact-left">
                <iframe :src="foot.address_link" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="contact-right">
                <h4 class="px28-800">{{ $t("contacts") }}</h4>
                <p class="px18 fw500 black"><span class="fw600">Адрес:</span> {{ foot.address }}</p>
                <p class="px18 fw600 black">Телефон: <a :href="'tel:' + foot.phone">{{ foot.phone }}</a></p>
                <p class="px18 fw600 black">WhatsApp: <a :href="'https://wa.me/' + foot.whats_app">{{ foot.whats_app }}</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import navBar from '@/components/ui/navBar.vue';
import axios from 'axios';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
export default {
  components: { navBar, BreadCrumb },
  data() {
    return {
        foot: "",
        category: [
            {
                link: "/",
                title: this.$t("main")
            }, 
            {
                link: "",
                title: this.$t("contacts")
            }
        ],
    }
  },
  created() {
    this.getFooter();
  },
  methods: {
    getFooter() {
      axios.get('get-footer')
      .then(response => {
          this.foot = response.data.contacts
      })
      .catch(e=> {
          this.errors.push(e);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.contact {
    display: flex;
    min-height: 50vh;
    margin-top: 30px;
    &-left {
        width: 45%;
        margin-right: 96px;
        iframe {
            width: 100%;
            height: 300px;
        }
    }
    &-right {
        h4 {
            margin-bottom: 12px;
            line-height: 34px;
        }
        p {
            margin-bottom: 20px;
            a {
                color: #000;
                text-decoration: underline;
            }
        }
    }
}
@media (max-width: 970px) {
    .contact {
        flex-direction: column-reverse;
        &-left {
            width: 100%;
        }
        &-right {
            h4 {
                font-size: 15px;
            }
            p {
                font-size: 12px;
                margin-bottom: 15px;
            }
        }
    }
}
</style>