<template>
  <div class="container">
    <div class="stock" v-if="MAIN_PAGE?.products">
      <p class="arrival-title section-title">{{ $t("sale") }}</p>
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :modules="modules"
        class="mySwiper"
        :breakpoints="breakpoints"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :loop="true"
        v-if="MAIN_PAGE.products.sale"
      >
        <swiper-slide
          v-for="(item, index) in MAIN_PAGE.products.sale"
          :key="index"
        >
          <Card :PRODUCT_CARD="item" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay } from "swiper";
import Card from "./Card.vue";
import { mapState } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Card,
  },
  props: ["MAIN_PAGE"],
  data() {
    return {
      breakpoints: {
        970: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
        0: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.arrival-title {
  margin-bottom: 30px;
}
.stock {
  margin: 110px 0;
  position: relative;
}
.soon {
  margin: 110px 0;
}
.mySwiper {
  padding: 15px 10px;
}
.number {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: -25px;
    img {
      height: 230px;
      z-index: 11;
    }
    p {
      color: #C41230;
      font-size: 64px;
      font-weight: 900;
      line-height: 65px;
    }
  }
@media (max-width: 1440px) {
  .inst {
    &-card {
      &__img {
        img {
          width: 215px;
          height: 215px;
        }
      }
    }
  }
}
@media (max-width: 970px) {
  .soon {
    margin: 50px 0;
  }
  .stock {
    margin: 30px 0;
  }
  .arrival-title {
    margin: 0;
  }
  .number {
    top: -10px !important;
  }
}
</style>
