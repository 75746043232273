<template>
  <div class="" :class="{'beige' : this.isHome}">
    <div class="head-bottom container">
      <!-- <div class="head-bottom__logo">
        <a href="/"><img src="@/assets/images/logo.jpg" alt="" /></a>
      </div> -->
      <div class="head-btm__mobile container d_none">
        <div class="head-btm__mobile-left">
          <img
            @click="activeMenu = true"
            class="head-burger"
            src="@/assets/images/burger-black.png"
            alt=""
          />
          <Transition>
          <div class="mobile-head" v-if="activeMenu">
            <div class="mobile-head__wrap">
              <div class="close">
                <img
                  @click="activeMenu = false"
                  src="@/assets/images/burger-white.png"
                  alt=""
                  class="close-img"
                />
                <router-link to="/" class="close-wrap" @click="activeMenu = false">
                  <img src="@/assets/images/logo-white.png" class="close-logo" alt="">
                </router-link>
              </div>
              <ul style="margin-bottom: 10px;">
                <li @click="activeMenu = false">
                  <router-link to="/catalog/palto-vesna-osen">{{ $t("catalog") }}</router-link>
                </li>
                <li @click="activeMenu = false">
                  <router-link to="/blog">Блог</router-link>
                </li>
              </ul>
              <ul style="margin-bottom: 10px;">
                <li @click="activeMenu = false">
                  <router-link to="/delivery">{{ $t("delivery") }}</router-link>
                </li>
                <li @click="activeMenu = false">
                  <router-link to="/contacts">{{ $t("contacts") }}</router-link>
                </li>
              </ul>
              <div class="mobile-line">
                <div class="mobile-line__wrap"></div>
              </div>
              <div class="mobile-head__btm">
                <ul>
                  <li v-for="(item, index) in HEADER.categories" :key="index" @click="activeMenu = false"><router-link :to="{path: `${'/catalog/' + item.slug}`}">{{ item.title }}</router-link></li>
                  <!-- <li><router-link to="/">Коллекция Осень-весна</router-link></li>
                  <li><router-link to="/">Пальто зима</router-link></li>
                  <li><router-link to="/">Пальто весна</router-link></li>
                  <li><router-link to="/">Дубленки</router-link></li>
                  <li><router-link to="/">Пуховики</router-link></li>
                  <li><router-link to="/">Шубы</router-link></li> -->
                </ul>
                <!-- <ul>
                  <li><router-link to="/">Тедди 100% шерсть</router-link></li>
                  <li><router-link to="/">Тедди 100% шерсть</router-link></li>
                  <li><router-link to="/">Куртки</router-link></li>
                  <li><router-link to="/">Парки</router-link></li>
                  <li><router-link to="/">Весенняя обувь</router-link></li>
                  <li><router-link to="/">Сумки классические</router-link></li>
                  <li><router-link to="/">Сумки Luxe</router-link></li>
                </ul> -->
              </div>
            </div>
          </div>
        </Transition>
        </div>
      </div>
      <div class="head-bottom__right">
        <select
          class="px20-400"
          name=""
          id=""
          v-model="lang"
          @change="handleChange($event)"
        >
          <option value="ru" selected>RU</option>
          <option value="kz">KZ</option>
          <!-- <option value="en">EN</option> -->
        </select>
        <img
          src="@/assets/images/search.svg"
          class=""
          alt=""
          @click="activeSearch = !activeSearch"
        />
        <Transition>
          <input
            class="head-bottom__right-input"
            ref="search"
            type="text"
            placeholder="Искать"
            v-if="activeSearch"
            v-model="search_input"
            @keyup="searchHandle($event)"
          />
        </Transition>
        <Transition>
          <div class="dropdown-res" v-if="activeSearch">
            <div v-if="isResult">
              <div class="search-result">
                <div
                  class="search-result__wrap"
                  v-if="SEARCH_RESULT.products.data.length"
                >
                  <router-link
                    :to="{
                      path: `/product/${result.slug}`,
                    }"
                    v-for="result in SEARCH_RESULT.products.data"
                    :key="result.id"
                    @click="activeSearch = false"
                  >
                    <span v-html="result.title"></span>
                  </router-link>
                </div>
                <div
                  class="search-result__wrap"
                  v-else
                >
                  {{ $t("modals.noRes") }}
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <router-link to="/favorites" class="goods-item">
          <img src="@/assets/images/goods.svg" alt="" />
          <span>{{ favouriteList.length || 0 }}</span>
        </router-link>
        <router-link to="/basket" class="basket-item" v-if="isAuth">
          <img src="@/assets/images/basket.svg" alt="" />
          <span>{{ cardLength || 0 }}</span>
        </router-link>
        <div @click="isOpen = true" class="basket-item" v-if="!isAuth">
          <img src="@/assets/images/basket.svg" alt="" />
          <span>{{ cardLength || 0 }}</span>
        </div>
        <img
          v-if="!isAuth"
          @click="isOpen = true"
          src="@/assets/images/login.svg"
          alt=""
        />
        <img
          v-if="isAuth"
          @click="toAccount"
          src="@/assets/images/login.svg"
          alt=""
        />
        <img
          v-if="isAuth"
          @click="logOut"
          src="@/assets/images/logout.svg"
          alt=""
        />
      </div>
    </div>
    <modalVue :open="isOpen" @close="isOpen = !isOpen">
      <div class="modal">
        <p class="modal-title px24 fw500">{{ $t("modals.regM") }}</p>
        <div class="modal-tab">
          <personalTab
            :tabItems="tabItems"
            @setTab="selectNewsTab"
            :selected="selectedNewsTab"
            @click="isRegister = false"
          >
          </personalTab>
          <tabBlock
            class="modal-tab__block"
            :isSelected="selectedNewsTab === this.$t('modals.login')"
          >
            <input
              v-model="v$.phone.$model"
              v-mask="'+7##########'"
              type="text"
              :placeholder="this.$t('baskets.phone')"
              class="input"
            />
            <template v-for="(error) of v$.phone.$errors" :key="error">
                <p class="errorText">{{ error.$message }}</p>
            </template>
            <input
                v-model="v$.password.$model"
                type="password"
                :placeholder="this.$t('modals.passw')"
                class="input"
              />
            <template v-for="(error) of v$.password.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <div class="modal-check">
              <span>
                <input id="check" type="checkbox" />
                <label for="check">{{ $t("modals.rem") }}</label>
              </span>
              <a @click="more = true">{{ $t("modals.que") }}</a>
            </div>
            <div class="modal-check__more" v-if="more">
              <p>
                {{ $t("modals.ans") }}
              </p>
              <a @click="more = false"
                >{{ $t("modals.hide") }}<img src="@/assets/images/hide_arrow_up.svg" alt=""
              /></a>
            </div>
            <div class="modal-pass">
              <p
                @click="(isOpen2 = true), (isOpen = false)"
                style="cursor: pointer"
              >
              {{ $t("modals.forget") }}
              </p>
            </div>
            <div class="modal-btn">
              <button @click="onSubmit">{{ $t("modals.login") }}</button>
            </div>
          </tabBlock>
          <tabBlock
            class="modal-tab__block"
            :isSelected="selectedNewsTab === this.$t('modals.reg')"
          >
            <div class="modal-wrap">
              <p class="text" style="margin-top: 10px">
                {{ $t("modals.ly") }}
              </p>
              <input
                v-if="!isRegister"
                type="text"
                :placeholder="this.$t('baskets.name')"
                class="input"
                v-model="v$.name.$model"
              />
              <template v-for="(error) of v$.name.$errors" :key="error">
                <p v-if="!isRegister" class="errorText">{{ error.$message }}</p>
              </template>
              <input
                v-if="!isRegister"
                v-model="v$.password.$model"
                type="password"
                :placeholder="this.$t('modals.passw')"
                class="input"
              />
              <template v-for="(error) of v$.password.$errors" :key="error">
                <p v-if="!isRegister" class="errorText">{{ error.$message }}</p>
              </template>
              <input
                v-if="!isRegister"
                type="text"
                placeholder="E-mail"
                class="input"
                v-model="v$.email.$model"
              />
              <template v-for="(error) of v$.email.$errors" :key="error">
                <p v-if="!isRegister" class="errorText">{{ error.$message }}</p>
              </template>
              <input
                v-if="!isRegister"
                type="text"
                :placeholder="this.$t('baskets.phone')"
                class="input"
                v-model="v$.phone.$model"
                v-mask="'+7##########'"
              />
              <template v-for="(error) of v$.phone.$errors" :key="error">
                <p v-if="!isRegister" class="errorText">{{ error.$message }}</p>
              </template>
              <input
                v-if="isRegister"
                type="text"
                :placeholder="this.$t('modals.code')"
                class="input"
                v-model="v$.code.$model"
              />
              <template v-for="(error) of v$.code.$errors" :key="error">
                <p v-if="isRegister" class="errorText">{{ error.$message }}</p>
              </template>
              <div v-if="!isRegister" class="modal-btn">
                <button @click="getRegister">{{ $t("modals.regist") }}</button>
              </div>
              <div v-if="isRegister" class="modal-btn">
                <button @click="confirmCode">{{ $t("send") }}</button>
              </div>
              <div v-if="!isRegister" class="check">
                <input id="check1" checked type="checkbox" />
                <label for="check1"
                  >{{ $t("agree") }}.</label
                >
              </div>
              <div v-if="!isRegister" class="check">
                <input id="check2" checked type="checkbox" />
                <label for="check2">{{ $t("modals.sub") }}</label>
              </div>
            </div>
          </tabBlock>
        </div>
      </div>
    </modalVue>
    <modalVue :open="isOpen2" @close="isOpen2 = !isOpen2">
      <div class="reset">
        <div class="reset-title px18 fw600">{{ $t("modals.pass") }}</div>
        <div class="reset-input">
          <input v-model="v$.email.$model" placeholder="Email" type="text" class="input" />
          <template v-for="(error) of v$.email.$errors" :key="error">
            <p class="errorText">{{ error.$message }}</p>
          </template>
        </div>
        <button class="button-active" @click="resetPass">{{ $t("send") }}</button>
      </div>
    </modalVue>
    <div class="visible" v-if="activeSearch" @click="resetSearch"></div>
  </div>
</template>

<script>
import modalVue from "@/components/modal/Modal.vue";
import personalTab from "../tab/personalTab.vue";
import tabBlock from "../tab/tabBlock.vue";
import { ref } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { modalVue, personalTab, tabBlock },
  data() {
    const lang = localStorage.getItem("lang") || "ru";
    return {
      v$: useVuelidate(),
      isHome: false,
      lang: lang,
      selectedNewsTab: this.$t('modals.login'),
      tabItems: [this.$t('modals.login'), this.$t('modals.reg')],
      isOpenBrands: false,
      isBrand: false,
      isCatalog: false,
      isGood: false,
      isBasket: false,
      more: false,
      isRegister: false,
      isResult: false,
      name: null,
      phone: null,
      password: null,
      email: null,
      code: "1234",
      piece: 1,
      activeSearch: false,
      activeMenu: false,
      confirm: false
    };
  },
  validations() {
      return {
        name: {
          required: helpers.withMessage(this.$t('req'), required),
        },
        code: {
          required: helpers.withMessage(this.$t('req'), required),
        },
        email: {
            required: helpers.withMessage(this.$t('req'), required),
            email: helpers.withMessage(this.$t('reqMail'), email),
        },
        password: {
            required: helpers.withMessage(this.$t('req'), required),
            minLength: helpers.withMessage(this.$t('minLength'),minLength(6)),
        },
        phone: {
          required: helpers.withMessage(this.$t('req'), required),
          minLength: helpers.withMessage(this.$t('req'),minLength(11)),
        },
      };
    },
  setup() {
    const isOpen = ref(false);
    const isOpen2 = ref(false);
    return {
      isOpen,
      isOpen2,
    };
  },
  created() {
    this.checkAuth();
    this.CART_LENGTH_ACTION();
    this.CART_FAVOURIT_LENGTH_ACTION()
    if(this.$route.path === '/') {
      this.isHome = true
    }
  },
  methods: {
    ...mapActions([
      "GET_HEADER",
      "checkAuth",
      "logout",
      "LOCALSTORAGE_PRODUCT",
      "FETCH_PRODUCT_BY_ID",
      "GET_SEARCH_RESULT",
      "FETCH_FAVOURITE_BY_ID",
      "CART_LENGTH_ACTION",
      "CART_FAVOURIT_LENGTH_ACTION"
    ]),
    resetSearch() {
      this.activeSearch = false;
      this.search_input = ""
    },
    async searchHandle(e) {
      let q = e.target.value;
      if (q.length >= 3) {
        await this.GET_SEARCH_RESULT({
          text: q,
          lang: localStorage.getItem("lang"),
        }).then(() => {
          this.isResult = true;
        });
      } else {
        this.isResult = false;
      }
      console.log(this.SEARCH_RESULT);
    },
    resetPass() {
      this.v$.$validate();
      if(this.email) {
        this.v$.$validate();
        axios
        .post("password/email", {
          email: this.email,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(this.$t('modals.passSuc'));
            this.isOpen2 = false;
          } else if(res.status === 404) {
            toast.error(this.$t("errMail"));
          }
        })
        .catch(() => {
          toast.error(this.$t('req'))
        })
      }
    },
    activeSub(index) {
      this.isSub = index;
      // this.isCatalog = false;
    },
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    toAccount() {
      this.$router.push("/account");
      this.isOpen = false;
    },
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    getRegister() {
      this.v$.$validate();
      axios
        .post("auth/register", {
          name: this.name,
          phone: this.phone,
          email: this.email,
          password: this.password,
        })
        .then((res) => {

          if (res.status === 200) {
            toast.success(this.$t('modals.enterCode'));
            this.isRegister = true;
            if(this.isAuth) {
              localStorage.setItem("access_token", res.data.token);
              this.checkAuth();
              this.name = "",
              this.phone = "",
              this.email = "",
              this.password = ""
            }
            else {
              return !this.checkAuth(),
              this.isAuth = false,
              this.confirm = false
            }
          }
          if (res.status === 400) {
            toast.error(this.$t('modals.logYet'));
          }
        })
        .catch((error) => {
          toast.error(this.$t('modals.logYet'));
          console.error("There was an error!", error);
        });
    },
    confirmCode() {
      this.v$.$validate();
      axios
        .post("/confirm-code", {
          code: this.code,
          phone: this.phone,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(this.$t('modals.regSuccess'));
            this.isOpen = false;
            this.isAuth = true
            this.confirm = true;
            this.$router.push("/account");
            // this.SET_USER(res.data.user);
            localStorage.setItem("access_token", res.data.token);
            this.checkAuth();
          }
          else {
            toast.error(this.$t('modals.errData'))
            this.isAuth = false
          }
        })
        .catch((error) => {
          console.error("There was an error1!", error);
        });
    },
    onSubmit() {
      this.v$.$validate();
      axios
        .post("auth/login", {
          phone: this.phone,
          password: this.password,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(this.$t('modals.success'));
            console.log(this.isAuth);
            this.isOpen = false;
            this.password = "";
            this.phone = "";
            this.$router.push("/account");
            localStorage.setItem("access_token", res.data.access_token);
            this.checkAuth();
          }
        })
        .catch((error) => {
          toast.error(this.$t('modals.errLog'));
          console.error("There was an error!", error);
        });
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$store.dispatch("checkAuth");
      this.$router.push("/");
    },
  },
  mounted() {
    this.GET_HEADER();
    this.LOCALSTORAGE_PRODUCT(this.product);
  },
  computed: {
    ...mapGetters({
      HEADER: "HEADER",
      isAuth: "getIsAuth",
      isUnAuth: "getUnAuth",
      getUser: "getUser",
      order_product: "GET_ORDER_PRODUCT",
      cartList: "GET_CART_LIST",
      favouriteList: "GET_FAVOURITE_LIST",
      basketList: "GET_PRODUCTS_BY_ID",
      userData: "getUser",
      cardLength: "GET_CART_LENGTH"
    }),
    ...mapGetters(["SEARCH_RESULT"]),
    ...mapState(["cdn"]),
    favourtiteData() {
      let newCards = {
        ids: [],
        counts: [],
        test: "",
      };
      this.favouriteList.map((item) => {
        newCards.ids.push(item.id);
        newCards.counts.push(item.count);
      });
      return newCards;
    },
  },
  watch: {
    cardLength(){
      if (this.isBasket === true) {
        this.FETCH_PRODUCT_BY_ID({ id: this.basketData.ids });
      }
    },
    isOpen: function () {
      if (this.isOpen) {
        // document.body.style.overflow = "hidden";
        return;
      }
      // document.body.style.overflow = "auto";
      this.name = ""
      this.phone = ""
      this.email =""
      this.password = ""
    },
    isOpen2: function () {
      if (this.isOpen) {
        // document.body.style.overflow = "hidden";
        return;
      }
      this.email = ""
      // document.body.style.overflow = "auto";
    },
    userData() {
      (this.name = this.userData.name),
        (this.phone = this.userData.phone_number);
    },
  }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/header.scss";
</style>
