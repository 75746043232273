<template>
  <div class="container">
    <nav-bar/>
    <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
    <div class="basket">
      <div class="basket-left" v-if="basketList.length > 0">
        <div class="basket-left__card">
          <p class="px24 fw500" style="margin-bottom: 20px">{{ $t("basket") }}</p>
          <div class="box" v-for="(item, idx) in cartList" :key="'a' + idx">
            <p style="display: none;">{{ selectedProduct = item }}</p>
            <div class="goods-card">
              <tab-basket-card :productCard="item" :areaNumber="idx + 1" />
            </div>
          </div>
        </div>
        <button @click="toMain" class="basket-left__btn">
          {{ $t("baskets.cn") }}
        </button>
        <div class="more m_none d_none">
          <p class="more-title px24 fw500">{{ $t("baskets.dop") }}</p>
          <div class="more-items">
            <card class="more-item" />
            <card class="more-item" />
            <card class="more-item" />
          </div>
        </div>
      </div>
      <div class="basket-right"  v-if="basketList.length > 0">
        <p class="px28-500">{{ $t("baskets.order") }}</p>
        <p class="px24 fw500 basket-right__desc">{{ $t("baskets.userData") }}</p>
        <div class="basket-right__inputs">
          <input
            v-model="userModel.name"
            :placeholder="this.$t('baskets.name')"
            type="text"
            class="input"
          />
          <input
            :placeholder="this.$t('baskets.lname')"
            v-model="userModel.surname"
            type="text"
            class="input"
          />
          <input
            placeholder="E-mail"
            type="text"
            class="input"
            v-model="userModel.email"
          />
          <input
            :placeholder="this.$t('baskets.phone')"
            type="text"
            class="input"
            v-model="userModel.phone_number"
          />
        </div>
        <div class="basket-right__order">
          <p style="display: none;" v-if="selectedNewsTab == `${this.$t('del1')}`">{{ this.delivery_type = "delivery" }}</p>
          <p style="display: none;" v-else>{{ this.delivery_type = "self" }}</p>
          <deliveryTab
            :tabItems="tabItems"
            @setTab="selectNewsTab"
            :selected="selectedNewsTab"
          >
          </deliveryTab>
          <tabBlock :isSelected="selectedNewsTab === `${this.$t('del1')}`">
            <div class="address">
              <p class="px16 fw500">{{ $t("add") }}</p>
              <div class="address-inputs">
                <select name="" id="" class="input" v-model="address.region" @change="changeRegion(address.region)">
                  <option selected disabled value="">{{ $t("baskets.reg") }}</option>
                  <option v-for="(item, index) in region" :key="index" :value="item"> {{ item.title }} </option>
                </select>
                <select name="" id="" class="input" v-model="address.city">
                  <option selected disabled value="">{{ $t("baskets.city") }}</option>
                  <option v-for="(item, index) in reg" :key="index" :value="item.title">{{ item.title }}</option>
                </select>
                <input
                  type="text"
                  v-model="address.street"
                  :placeholder="this.$t('baskets.st')"
                  class="input"
                />
                <p class="px14 example d_none">{{ $t("baskets.ex") }}</p>
                <input v-model="address.house" type="text" :placeholder="this.$t('baskets.kv')" class="input" />
              </div>
              <p class="px14 example m_none">{{ $t("baskets.ex") }}</p>
            </div>
            <div class="date">
              <p class="px16 fw500">{{ $t("baskets.date") }}</p>
              <input type="date" v-model="main_info.date" class="input" />
            </div>
          </tabBlock>
          <tabBlock :isSelected="selectedNewsTab === this.$t('baskets.pickup')">
            <div class="del">
              <div class="del-title">
                <p @click="isShop = false" :class="{active: !isShop}" style="cursor: pointer;">{{ $t("baskets.shops") }}</p>
              </div>
              <!-- {{ shop_id }} -->
              <div class="" v-if="!isShop">
                <div class="del-card" :class="{'active-shop' : index == activeShop}" v-for="(item, index) in addresses.shops" :key="index" @click="selectedShop(item, index)">
                  <img src="@/assets/images/geo_icon.svg" alt="" />
                  <p>{{ item.full_row }}</p>
                </div>
              </div>
            </div>
          </tabBlock>
          <div class="com">
            <p class="com-title px16 fw500">{{ $t("baskets.comm") }} <span class="gray">({{ $t("notReq") }})</span> </p>
            <textarea
              v-model="userModel.comment"
              name=""
              id=""
              cols="30"
              rows="7"
              class="com-text"
              :placeholder="this.$t('baskets.coms')"
            ></textarea>
          </div>
          <div class="code">
            <p class="code-title d_none px16 fw500">{{ $t("baskets.cupon") }}</p>
            <div class="code-line">
              <input type="text" v-model="code" class="input" placeholder="Введите промокод" />
              <button @click="checkCode">{{ $t("baskets.use") }}</button>
            </div>
            <div class="code-text">
              <p class="px18 fw400">{{ $t("baskets.prods") }}:</p>
              <p class="px16 fw600 price">{{ formatPrice(totalPrice) }}</p>
            </div>
            <div class="code-text" v-if="selectedNewsTab == this.$t('del1')">
              <p class="px18 fw400">{{ $t("baskets.cur") }}:</p>
              <p class="px16 fw600 price">{{ formatPrice(delivery.price) }}</p>
            </div>
            <div class="code-text">
              <p class="px18 fw400">{{ $t("baskets.ds") }}:</p>
              <p class="px16 fw600 price">{{ formatPrice(totalPrice * (percent/100)) }}</p>
            </div>
            <hr class="code-line" />
            <div class="code-final">
              <p class="px18 fw600">{{ $t("baskets.all") }}</p>
              <p v-if="selectedNewsTab == this.$t('del1')" class="px16 fw600 price">{{ formatPrice((parseFloat(totalPrice) + parseFloat(delivery.price)) - (parseFloat(totalPrice) * (parseFloat(percent)/100))) }}</p>
              <p v-else class="px16 fw600 price">{{ formatPrice(parseFloat(totalPrice) - (parseFloat(totalPrice) * parseFloat((percent/100)))) }}</p>
            </div>
          </div>
          <div class="code-input">
            <input id="check1" type="checkbox" />
            <label for="check1" class="px12 fw600" style="color: #766591"
              >{{ $t("baskets.call") }}</label
            >
          </div>
          <div class="code-input">
            <input id="check2" type="checkbox" />
            <label for="check2" class="px14"
              >{{ $t("agree") }}</label
            >
          </div>
          <div class="pay-btns">
            <button class="button-active" @click="FETCH_PAY((type = 'halyk'))" >{{ $t("baskets.pay1") }}</button>
            <button class="button-active" @click="isOpenModal = true">{{ $t("baskets.pay2") }}</button>
            <!-- <button class="button-active" @click="getOrder((pay_method = 'cash'))">Оплатить</button> -->
          </div>
        </div>
      </div>
      <p class="no-product px20 fw500" v-else>{{ $t("noBasket") }}</p>
    </div>
    <Transition>
      <div class="kaspi" v-if="isOpenModal">
        <div class="kaspi-wrap">
          <div class="kaspi-top">
            <img src="@/assets/images/main/little-qr.png" alt="">
            <p class="px28-800">Kaspi QR</p>
          </div>
          <p class="kaspi-skan px18-400">{{ this.$t("skan") }}</p>
          <img class="kaspi-qr" src="@/assets/images/main/kaspi.png" alt="">
          <p class="kaspi-name px14" style="margin: 10px 0;">ИП ЖАНУЗАКОВА<br/>ИП Жанузакова (Нур-Султан, улица Шокана Валиханова, 17)</p>
          <p class="kaspi-pay px22-600" style="margin-bottom: 10px;">{{ this.$t("payType") }}</p>
          <div class="kaspi__buttons">
            <div class="kaspi-gold roboto">
            GOLD
            </div>
            <div class="kaspi-red roboto">
              RED
            </div>
            <div class="kaspi-red roboto">
              KREDIT
            </div>
          </div>
          <!-- <div class="kaspi-label">
            <input checked type="checkbox" id="ch">
            <label class="roboto px14" for="ch">Я прочитал и согласен с условиями сайта</label>
          </div> -->
          <div class="kaspi-btn">
            <button class="button-active" @click="FETCH_PAY((type = 'kaspi'))">{{ this.$t("endPay") }}</button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <div v-if="isOpenModal" @click="isOpenModal = false" class="visible"></div>
</template>

<script>
import deliveryTab from "@/components/tab/deliveryTab.vue";
import tabBlock from "@/components/tab/tabBlock.vue";
import Card from "@/components/main/Card.vue";
import { mapActions, mapGetters } from "vuex";
import TabBasketCard from "@/components/tab/tabBasketCard.vue";
import axios from "axios";
import NavBar from '@/components/ui/navBar.vue';
import { useToast } from "vue-toastification";
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
// import useVuelidate from "@vuelidate/core";
// import { required, minLength, helpers, email } from "@vuelidate/validators";
const toast = useToast();
export default {
  components: { deliveryTab, tabBlock, Card, TabBasketCard, NavBar, BreadCrumb },
  data() {
    return {
      isOpenModal: false,
      delivery: "",
      selectedNewsTab: this.$t('del1'),
      tabItems: [this.$t("del1"), this.$t('baskets.pickup')],
      disableMinus: false,
      disablePlus: false,
      isActive: false,
      isActivePay: false,
      piece: 1,
      region: [],
      reg: [],
      addresses: [],
      percent: 0,
      isShop: false,
      regionId: null,
      products: [
        {
          id: null,
          count: null,
          color_id: null,
          size_id: null,
        },
      ],
      address: {
        city : "",
        region : "",
        street : "",
        house : "",
        shop_id: 1,
      },
      activeShop: false,
      userModel: {
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        comment: null,
      },
      main_info: {
        date: ""
      },
      category: [
        {
          link: "/",
          title: this.$t("main")
        }, 
        {
          link: "",
          title: this.$t("basket")
        }
      ],
    };
  },
  created() {
    this.FETCH_PRODUCT_BY_ID({ id: this.basketData.ids });
    this.FETCH_USER();
    this.getAddress();
    this.getRegion();
    this.getDeliveryPrice();
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      basketList: "GET_PRODUCTS_BY_ID",
      cartList: "GET_CART_LIST",
      userData: "getUser",
    }),
    basketData() {
      let newCards = {
        ids: [],
        counts: [],
        test: "",
      };
      this.cartList.map((item) => {
        newCards.ids.push(item.id);
        newCards.counts.push(item.count);
      });
      return newCards;
    },
    totalPrice() {
      let result = [];
      if (this.cartList) {
        if (this.cartList.length) {
          for (let item of this.cartList) {
            result.push(item.current_price * item.count);
          }
          result = result.reduce(function (sum, el) {
            return sum + el;
          });
          return result || 0;
        } else {
          return 0;
        }
      }
    },
  },
  methods: {
    ...mapActions(["FETCH_PRODUCT_BY_ID", "FETCH_USER"]),
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    checkCode() {
      axios.post('check-code', {
        code: this.code,
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then(res => {
        if(res.status === 200) {
          this.percent = res.data.percent;
          console.log('res promo: ', res);
        }
      })
      .catch(err => {
        console.log('promo err: ',err);
        toast.error('Промокод недействителен!');
      })
    },
    changeRegion(item) {
      this.regionId = item.id;
      console.log(this.regionId);
      this.getRegionById();
    },
    selectedShop(item, index) {
      this.activeShop = index;
      this.shop_id = item.id;
    },
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    toMain() {
      this.$router.push(`/`);
    },
    getAddress() {
      axios.get('addresses')
      .then( res => {
        this.addresses = res.data
      })
    },
    getRegion() {
      axios.get('get-region')
      .then( res=> {
        this.region = res.data.regions;
      })
    },
    getRegionById() {
      axios.get(`get-city?region_id=${this.regionId}`)
      .then( res=> {
        this.reg = res.data.cities
      })
    },
    minus() {
      if (this.piece > 1) {
        this.piece--;
      } else {
        this.disableMinus == true;
      }
    },
    plus() {
      this.piece++;
    },
    isActiveButton(index) {
      this.isActive = index
    },
    isActiveButton1(index) {
      this.isActivePay = index
    },
    deleteAll() {
      localStorage.removeItem("cart_products");
      this.$store.state.cartList = []
      this.totalPrice = 0;
      this.basketList.length = 0;
    },
    FETCH_PAY(type) {
      if (type === "halyk") {
        axios
        .post(
          "auth/get-order",
          {
            main_info: {
              name: this.userModel?.name,
              l_name: this.userModel?.surname,
              phone: this.userModel?.phone_number,
              email: this.userModel?.email,
              comment: this.userModel?.comment,
              delivery_type: this.delivery_type,
              date: this.main_info.date,
              // amount: this.totalPrice,
              deliver: "courier_delivery",
            },
            products: this.cartList.map(item => ({ ...item})),
            address: {
              region: this.address.region.title,
              city: this.address.city,
              street: this.address.street,
              house: this.address.house,
              shop_id: this.shop_id || this.addresses?.shops[0]?.id,
            },
            pay_type: 'halykpay',
            code: this.code,
          },
          {
            headers: {
              Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },
          }
        )
        .then((response) => {
              if(response.status === 200) {
                // console.log("res", response);
                let auth = response.data.token;
                let invoiceId = response.data.invoiceId;
                let amount = response.data.amount;
                // console.log('pay data: ' + auth, invoiceId, amount);
                halyk.showPaymentWidget(
                  this.createPaymentObject(auth, invoiceId, amount),
                  (res) => {
                    if (res.success) {
                      //   (this.isEnterBalance = false), this.GET_BALANCE();
                      this.deleteAll();
                      toast.success(this.$t("paySuccess"));
                    }
                  }
                );
              } else if(response.status === 400) {
                toast.error(this.$t('allErr'));
              }
          })
          .catch((err) => {
            console.log(err);
            toast.error(this.$t('allErr'));
          });
      } else if(type == "kaspi") {
        axios
        .post(
          "auth/get-order",
          {
            main_info: {
              name: this.userModel?.name,
              l_name: this.userModel?.surname,
              phone: this.userModel?.phone_number,
              email: this.userModel?.email,
              comment: this.userModel?.comment,
              delivery_type: this.delivery_type,
              date: this.main_info.date,
              deliver: "courier_delivery",
            },
            products: this.cartList.map(item => ({ ...item})),
            address: {
              region: this.address.region.title,
              city: this.address.city,
              street: this.address.street,
              house: this.address.house,
              shop_id: this.shop_id || this.addresses?.shops[0]?.id,
            },
            code: this.code,
            pay_type: 'kaspipay'
          },
          {
            headers: {
              Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },
          }
        )
        .then((response) => {
          if(response.status === 200) {
            toast.success(this.$t("paySuccess"));
            this.isOpenModal = false;
            this.deleteAll();
          } else if (response.status === 400) {
            toast.error(this.$t('allErr'));
            this.isOpenModal = false;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(this.$t('allErr'));
          this.isOpenModal = false;
        });
      }
    },
    createPaymentObject(auth, invoiceId, amount) {
      let paymentObject = {
        invoiceId: invoiceId,
        backLink: "",
        failureBackLink: "",
        postLink: "https://admin.terisarayi.kz/api/payment-result-success",
        failurePostLink:
          "https://admin.terisarayi.kz/api/payment-result-failed",
        language: this.lang === "ru" ? "rus" : "kaz",
        description: "Платёж Terisarayi",
        accountId: "toleBy48735637465",
        terminal: "67e34d63-102f-4bd1-898e-370781d0074d",
        amount: amount,
        currency: "KZT",
        cardSave: false,
      };
      paymentObject.auth = auth;
      return paymentObject;
    },
    getDeliveryPrice() {
      axios.get(
        'delivery-price'
      )
      .then(res => {
        this.delivery = res.data;
      })
    }
  },
  watch: {
    userData() {
      (this.userModel.name = this.userData.name),
        (this.userModel.phone_number = this.userData.phone_number);
      (this.userModel.surname = this.userData.sname),
        (this.userModel.email = this.userData.email);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/basket.scss';
</style>
