<template>
  <div class="main-slide" v-if="MAIN_PAGE">
    <!-- {{ MAIN_PAGE.slider }} -->
    <div class="head-bottom__gray" v-if="MAIN_PAGE.advertisingBanner">
      <p><a style="color: #9E9599;" :href="MAIN_PAGE.advertisingBanner.link" target="_blank" v-html="MAIN_PAGE.advertisingBanner.title"></a></p>
    </div>
    <swiper
        class="mySwiper swiper-h"
        :spaceBetween="0"
        :pagination="{
        clickable: true,
        }"
        :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }"
        :modules="modules"
        :loop="true"
    >
        <swiper-slide class="" v-for="slider in MAIN_PAGE.slider" :key="slider.id">
          <a :href="slider.url">
            <img :src="cdn + slider.image" loading="eager" alt="">
          </a>
        </swiper-slide>
        <!-- <swiper-slide class="d_none" v-for="slider in MAIN_PAGE.slider" :key="slider.id">
          <a :href="slider.url">
            <img :src="cdn + slider.image_mobile" alt="">
          </a>
        </swiper-slide> -->
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from 'swiper';
import { mapState } from 'vuex'
import "swiper/css";
export default {
    components: { Swiper, SwiperSlide},
    props: ["MAIN_PAGE"],
    setup() {
      return {
        modules: [Autoplay],
      };
    },
    computed: {
      ...mapState(['cdn'])
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/header.scss";
.main-slide {
    .swiper-slide {
        img {
            width: 100%;
            // height: 557px;
        }
    }
}
@media (max-width: 970px) {
  .main-slide {
    .swiper-slide {
      img {
        object-fit: cover;
        // height: 86vh;
        min-height: 200px;
        max-height: 400px;
      }
    }
  }
}
</style>