<template>
    <nav class="breadcrumb">
        <ul class="breadcrumb__items">
            <li v-for="(crumb, i) in crumbs" :key="i" class="breadcrumb__item">
                <router-link 
                    class="breadcrumb__btn" 
                    :class="{ disabled: isList(i) }"  :to="crumb.link" 
                    @click="selected(crumb)">
                    {{ crumb.title }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        crumbs: {
            type: Array,
            required: true,
        },
        links: {
            type: Array
        }
    },
    methods: {
        isList(index) {
            return index === this.crumbs.length - 1
        },
        selected(crumb) {
            this.$emit('selected', crumb)
        }
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    ul {
        list-style-type: none;
        li {
            a {
                text-decoration: none;
            }
        }
    }

    &__items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        flex-wrap: wrap;

    }



    &__item {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        a {
            color: #000000;
        }
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: '-';
            margin: 0 2px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

    }
}
@media (max-width: 970px) {
    .breadcrumb {
        margin-top: 40px;
        ul {
            display: flex;
            flex-wrap: nowrap;
        }
    }
}
// .disabled {
//     color: #a0a0a0 !important;
// }
</style>