<template>
    <div class="container" v-if="DELIVERY">
        <nav-bar/>
        <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
        <div class="del">
            <div class="del-left" v-if="DELIVERY?.deliveryItems">
                <h4 class="px28-800">{{ $t("del1") }}</h4>
                <div class="del-wrap">
                    <div class="del-wrap__card" v-for="(item, index) in DELIVERY.deliveryItems" :key="index">
                        <div class="del-wrap__card-top" @click="isClicked(item)">
                            <p class="px20 fw700 black" v-html="item.title"></p>
                            <img :class="{'rotate' : item.isOpen}" class="m_none" src="@/assets/images/main/red-arr.png" alt="">
                            <img :class="{'rotate' : item.isOpen}" class="d_none" src="@/assets/images/main/red-arr2.png" alt="">
                        </div>
                        <Transition>
                            <div class="del-wrap__card-desc" v-show="item.isOpen">
                                <p v-html="item.content"></p>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="del-right" v-if="DELIVERY?.paymentItems">
                <h4 class="px28-800">{{ $t('pay') }}</h4>
                <div class="del-wrap">
                    <div class="del-wrap__card" v-for="(item, index) in DELIVERY.paymentItems" :key="index">
                        <div class="del-wrap__card-top" @click="isClickedItem(item)">
                            <p class="px20 fw700 black" v-html="item.title"></p>
                            <img :class="{'rotate' : item.isOpened}" class="m_none" src="@/assets/images/main/red-arr.png" alt="">
                            <img :class="{'rotate' : item.isOpened}" class="d_none" src="@/assets/images/main/red-arr2.png" alt="">
                        </div>
                        <Transition>
                            <div class="del-wrap__card-desc" v-show="item.isOpened">
                                <p v-html="item.content"></p>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="del-btm">
            <img src="@/assets/images/main/choose-back2.png" alt="">
        </div>
    </div>
</template>

<script>
import navBar from '@/components/ui/navBar.vue'
import BreadCrumb from '@/components/ui/BreadCrumb.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { navBar, BreadCrumb },
  data() {
        return {
            delivery: [
                {
                    title: "Доставка по г. Астана",
                    desc: "Бесплатно доставим курьером ваш заказ по городу. Срок доставки 1-2 дня.Также вы можете забрать товар самовывозом из магазина по адресу ул. Валиханова,17.",
                    id: 1,
                    isOpen: false,
                },
            ],
            pay: [
                {
                    title: "Банковской картой",
                    desc: "Оплата происходит прямо на сайте через платежную систему. Подходят карты VISA, Mastercard.После оплаты на вашу почту придет чек об оплате, а также информация о содержимом вашего заказа.",
                    id: 1,
                    isOpened: false,
                },
                {
                    title: "Kaspi Pay",
                    desc: "Оплата происходит прямо на сайте через платежную систему. Подходят карты VISA, Mastercard.После оплаты на вашу почту придет чек об оплате, а также информация о содержимом вашего заказа.",
                    id: 2,
                    isOpened: false,
                },
            ],
            category: [
                {
                    link: "/",
                    title: this.$t("main")
                }, 
                {
                    link: "",
                    title: this.$t("delivery")
                }
            ],
        }
    },
    mounted() {
        this.GET_DELIVERY();
    },
    methods: {
        ...mapActions(["GET_DELIVERY"]),
        isClicked(item) {
            item.isOpen = !item.isOpen
        },
        isClickedItem(item) {
            item.isOpened = !item.isOpened
        }
    },
    computed: {
        ...mapGetters(["DELIVERY"]),
    }
}
</script>

<style lang="scss" scoped>
.rotate {
    transform: rotate(-90deg);
}
.del {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    &-left, &-right {
        width: 49%;
    }
    h4 {
        text-transform: uppercase;
        text-align: center;
    }
    &-wrap {
        &__card {
            padding: 12px 0;
            border-bottom: 1px solid #000;
            &-top {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                img {
                    width: 23px;
                    transition: all 0.4s;
                }
            }
            p {
                line-height: 24px;
            }
            &-desc {
                transition: all 0.4s;
                padding: 15px 0 0 0;
                p {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
    &-btm {
        margin: 78px 0;
        display: flex;
        justify-content: center;
        img {
            width: 35%;
        }
    }
}
@media (max-width: 970px) {
    .del {
        flex-direction: column;
        h4 {
            font-size: 15px;
        }
        &-left, &-right {
            width: 100%;
        }
        &-right {
            margin-top: 33px;
        }
        &-wrap {
            &__card {
                padding: 10px 0;
                &-top {
                    p {
                        font-size: 14px;
                    }
                }
                &-desc {
                    p {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
        &-btm {
            margin: 40px 0;
        }
    }
}
</style>