<template>
  <div class="promo" v-if="promo">
    <p v-if="promo.promocodeText" class="promo-title px16 fw600" v-html="promo.promocodeText.title"></p>
    <p v-if="promo.promocodeText" class="promo-desc px16 fw500" v-html="promo.promocodeText.description">
     
    </p>
    <div class="promo-table">
        <p class="px16 fw600">{{ $t("promocode.history") }}</p>
        <div class="promo-table__box">
            <table v-if="promo">
                <tr>
                    <th>Промокод</th>
                    <th>{{ $t("promocode.date") }}</th>
                    <th>{{ $t("promocode.pc") }}</th>
                    <th>{{ $t("promocode.act") }}</th>
                </tr>
                <tr v-for="(item, index) in promo.historyPromocodes" :key="index">
                    <td class="bold">{{ item.promocode }}</td>
                    <td class="normal">{{ format_date(item.created_at) }}</td>
                    <td class="bold">{{ item.percent }}</td>
                    <td class="normal">{{ format_date(item.expired_date) }}</td>
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from 'axios'
export default {
  data() {
    return {
      promo: [],
    }
  },
  created() {
    this.setPromo()
  },
  methods: {
    setPromo() {
      axios
        .post("auth/user-promocode", {}, {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },

        })
        .then((res) => {
          if (res.status === 200) {
            this.promo = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    format_date(value) {
        moment.locale('ru');
        return moment(String(value)).format('LL');
    },
  }
};
</script>

<style lang="scss" scoped>
.promo {
  &-title {
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &-table {
    margin-top: 80px;
    table {
      width: 100%;
    }
    th {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      &:first-child {
        text-align: left;
      }
    }
    td,
    th {
      border-bottom: 1px solid #c4c4c4;
      padding: 18px 0 24px 0;
      &:not(:first-child) {
        text-align: center !important;
      }
    }
    p {
      text-transform: uppercase;
      margin-bottom: 35px;
    }
    .bold {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }
    .normal {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #77757f;
    }
  }
}
</style>
