<template>
    <div class="content">
      <h3 class="result-title"> Поиск по сайту </h3>
      <div class="dropdown-result">
        <div v-if="isResult">
          <div class="search-results">
            <div v-if="SEARCH_RESULT.data.data.length">
              <div class="cards">
                <router-link class="cards-item" :to="{
                  path: `/news/${result.id}`,
                  params: { id: result.id },
                }" v-for="result in SEARCH_RESULT.data.data" :key="result.id">
                  <Card :background="result.image" :time="result.created_at" :title="result.title"
                    :discription="result.content"></Card>
                </router-link>
              </div>
            </div>
            <div v-else-if="!SEARCH_RESULT.data.length">
              <h4>{{ $t("header.noresult") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { onUnmounted } from "vue";
  
  export default {
    name: "Search",
    components: {
    },
    data() {
      const lang = localStorage.getItem("lang");
      return {
        isResult: true,
        isActive: false,
        lang: lang
      };
    },
    setup() {
      const handleKeyup = (event) => {
        if (event.keyCode === 13) {
          window.location.reload();
          console.log('enter!!');
        }
      };
      onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
  
      // return { close };
    },
    computed: {
      ...mapGetters(["currentLang", "SEARCH_RESULT"]),
      langSet(){
        return localStorage.getItem('lang')
      }
    },
    methods: {
      ...mapActions(["GET_SEARCH_RESULT"]),
    },
    created(){
      this.GET_SEARCH_RESULT({ text: this.$route.query.data, lang: localStorage.getItem("lang") }).then(() => {
          });
    },
    watch: {
      langSet(){
        console.log('asdsads')
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    &-item,
    a {
      width: 23%;
      margin-right: 2%;
      margin-bottom: 30px;
    }
  
    @media (max-width: 992px) {
      &-item {
        width: 49% !important;
        margin-right: 1% !important;
        margin-bottom: 15px !important;
      }
    }
  }
  
  .dropdown {
    &-result {
      padding: 40px 0;
  
      .search {
        &-results {
          a {
            text-decoration: none;
          }
  
          h4 {
            color: #000;
            padding: 20px 0 0 0;
            // border-bottom: 1px solid #000;
            margin: 0;
            transition: 0.6s;
  
            &:hover {
              color: #b7b4b4;
              transition: 0.6s;
            }
          }
        }
      }
    }
  }
  </style>
  