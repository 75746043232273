<template>
    <div class="container">
        <nav-bar/>
        <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
        <div class="fav">
            <h4 class="fav-title section-title">{{ $t("fav") }}</h4>
            <div class="fav-wrap" v-if="GET_FAVOURITE_LIST.length > 0">
                <div class="fav-item" v-for="(item, index) in GET_FAVOURITE_LIST" :key="index">
                    <Card class="fav-item__card" :PRODUCT_CARD="item" />
                </div>
                <!-- {{ GET_FAVOURITE_LIST.length }} -->
            </div>
            <p class="fav-no px20 fw500" v-else>{{ $t("noFav") }}</p>
        </div>
    </div>
</template>

<script>
import navBar from '@/components/ui/navBar.vue';
import Card from '@/components/main/Card.vue';
import { mapGetters } from 'vuex';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
export default {
  components: { navBar, Card, BreadCrumb },
  data() {
    return {
        category: [
            {
                link: "/",
                title: this.$t("main")
            }, 
            {
                link: "",
                title: this.$t("fav")
            }
      ],
    }
  },
  computed: {
    ...mapGetters(["GET_FAVOURITE_LIST"])
  }
}
</script>

<style lang="scss" scoped>
.fav {
    min-height: 60vh;
    &-title {
        text-align: center;
    }
    &-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 40px 0;
    }
    &-item {
        width: 24%;
        margin-right: 1%;
        margin-bottom: 20px;
    }
    &-no {
        text-align: center;
        margin: 30px 0;
    }
}
@media (max-width: 600px) {
    .fav {
        margin: 40px 0;
        min-height: 40vh;
        &-wrap {
            justify-content: space-between;
            margin: 20px 0;
        }
        &-item {
            width: 49%;
            margin-right: 0;
        }
        &-no {
            font-size: 14px;
        }
    }
}
</style>