<template>
  <div class="container">
    <div class="modal">
        <div class="modal-wrap">
            <p class="px18 fw600">Восстановление пароля</p>
            <input v-model="password" type="password" class="input">
            <input v-model="password_confirmation" type="password" class="input">
            <!-- {{ $route.query }} -->
            <button class="button-active" @click="sendReset">Отправить</button>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
    data() {
        return {
            password_confirmation: null,
            password: null,
            email: null,
        }
    },
    methods: {
        sendReset() {
            this.form = {
                password: this.password,
                password_confirmation: this.password_confirmation,
                email: this.$route.query.email,
                token: this.$route.query.token
            }
            axios.post('password/reset',this.form, {
                headers: {
                Authorization: this.$route.query.token
                    ? `Bearer ${this.$route.query.token}`
                    : null,
                },
            })
            .then( res => {
                if(res.status === 200) {
                    toast.success(this.$t('changePassword'))
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>
 .modal {
    &-wrap {
        width: 60%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .input {
            margin-top: 25px;
            width: 300px;
        }
        button {
            width: 320px;
            margin-top: 30px;
        }
    }
 }
</style>