<template>
    <div class="container">
        <nav-bar/>
        <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
        <div class="blog-wrap" v-if="PAGE?.pages">
            <h4 class="blog-title section-title" v-html="PAGE.pages?.title"></h4>
            <div class="blog">
                <div class="blog-left">
                    <img :src="cdn + PAGE.pages?.image" alt="">
                </div>
                <div class="blog-right">
                    <p class="px18-400" v-html="PAGE.pages?.description"></p>
                    <div class="yt">
                      <iframe
                          width="560"
                          height="315"
                          :src="videoSrc"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                      ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import navBar from '@/components/ui/navBar.vue'
import {mapState, mapActions, mapGetters} from 'vuex'
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
export default {
  components: { navBar, BreadCrumb },
  data() {
    return {
        category: [
            {
                link: "/",
                title: this.$t("main")
            }, 
            {
                link: "",
                title: "Блог"
            }
        ],
        videoSrc: "https://www.youtube.com/embed/YHBCEjWtPDE"
    }
  },
  mounted() {
    this.GET_PAGE();
  },
  methods: {
    ...mapActions(["GET_PAGE"])
  },
  computed: {
    ...mapGetters(['PAGE']),
    ...mapState(['cdn']),
  },
}
</script>

<style lang="scss" scoped>
.yt {
  margin-top: 2rem;
}

.blog {
    display: flex;
    justify-content: space-between;
    &-title {
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    &-wrap {
        margin-top: 30px;
    }
    &-left {
        width: 45%;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    &-right {
        width: 53%;
    }
}
@media (max-width: 970px) {
  iframe {
    width: 100%;
    height: 100%;
  }

    .blog {
        flex-direction: column;
        &-left, &-right {
            width: 100%;
        }
        &-left {
            img {
                height: 374px;
                object-fit: cover;
            }
        }
        &-right {
            margin-top: 30px;
        }
    }
}
</style>