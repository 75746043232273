<template>
  <div class="container">
    <nav-bar/>
    <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
    <div class="acc">
      <p class="px28-500 acc-title" style="text-transform: uppercase;">{{ $t("acc") }}</p>
      <div class="acc-wrap">
        <div class="acc-left">
          <personal-tab
            :tabItems="tabItems"
            @setTab="selectNewsTab"
            :selected="selectedNewsTab"
          >
          </personal-tab>
          <!-- <p @click="toBasket" class="text">{{ $t("basket") }}</p> -->
        </div>
        <div class="acc-right">
          <tab-block :isSelected="selectedNewsTab === this.$t('promo')"
            ><promocode
          /></tab-block>
          <tab-block :isSelected="selectedNewsTab === this.$t('order')"
            ><order
          /></tab-block>
          <tab-block :isSelected="selectedNewsTab === this.$t('rev')">
          <reviews/></tab-block>
          <tab-block
            :isSelected="selectedNewsTab === this.$t('subs')"
          >
            <Subscribe/>
        </tab-block>
          <tab-block :isSelected="selectedNewsTab === this.$t('mydata')">
            <div class="cab">
              <div class="cab-title px16 fw500">{{ $t('mydata') }}</div>
              <!-- {{ this.userData }} -->
              <div class="cab-inputs">
                <div class="cab-inputs__top">
                  <input :disabled="disabled" class="input" v-model="userData.name" :placeholder="this.$t('baskets.name')" type="text" />
                  <input :disabled="disabled" class="input" v-model="userData.sname" :placeholder="this.$t('baskets.lname')" type="text" />
                  <input :disabled="disabled" class="input" v-model="userData.lname" :placeholder="this.$t('baskets.lname2')" type="text" />
                </div>
                <div class="cab-inputs__btm">
                  <div class="card">
                    <p>{{ $t("email") }}</p>
                    <input :disabled="disabled" class="input" v-model="userData.email" placeholder="E-mail" type="text" />
                  </div>
                  <div class="card">
                    <p>{{ $t("phone") }}</p>
                    <input :disabled="disabled" v-mask="'+7##########'" class="input" v-model="userData.phone_number" placeholder="+7" type="text" />
                  </div>
                  <div class="card">
                    <p>{{ $t("date") }}</p>
                    <input :disabled="disabled" class="input" v-model="userData.bday" placeholder="" type="date" />
                  </div>
                </div>
              </div>
              <div class="cab-btns">
                <div class="cab-btns__left">
                  <button v-if="!disabled" class="button-active" @click="updateUser">{{ $t("save") }}</button>
                  <button v-if="disabled" class="button" @click="disabled = false">{{ $t("change") }}</button>
                </div>
                <div class="cab-btns__right">
                  <p @click="isOpen = true">{{ $t("changePass") }}</p>
                </div>
              </div>
            </div>
          </tab-block>
          <!-- <tab-block :isSelected="selectedNewsTab === 'Корзина'"></tab-block> -->
        </div>
      </div>
    </div>
    <Modal :open="isOpen" @close="isOpen = !isOpen">
      <div class="modal">
        <p class="modal-title px24 fw500">{{ $t("changePass1") }}</p>
        <div class="modal-inputs">
          <input v-model="old_password" :placeholder="this.$t('modals.crPass')" type="password" class="input">
          <input v-model="new_password" :placeholder="this.$t('modals.passw')" type="password" class="input">
          <input :placeholder="this.$t('modals.resetPass')" type="password" class="input">
        </div>
        <div class="modal-btn">
          <button class="button-active" @click="changePassword">{{ $t("changePass") }}</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import personalTab from "@/components/tab/accountTab.vue";
import TabBlock from "@/components/tab/tabBlock.vue";
import promocode from "@/components/account/Promocode.vue";
import order from "@/components/account/Order.vue";
import {ref} from "vue"
import { mapGetters } from "vuex";
import axios from "axios";
import Modal from "@/components/modal/Modal.vue"
import reviews from '@/components/account/Reviews.vue';
import Subscribe from "@/components/account/Subscribe.vue"
import NavBar from '@/components/ui/navBar.vue';
import { useToast } from "vue-toastification";
import BreadCrumb from "@/components/ui/BreadCrumb.vue";

const toast = useToast();
// import Cabinet from "@/components/account/Cabinet.vue";
export default {
  components: { personalTab, TabBlock, promocode, order, Modal, reviews, Subscribe, NavBar, BreadCrumb },
  data() {
    return {
      name: "",
      sname: "",
      lname: "",
      email: "",
      phone_number: null,
      phone: null,
      bday: "",
      new_password: "",
      old_password: "",
      disabled: true,
      userData: [],
      selectedNewsTab: this.$t('mydata'),
      tabItems: [
        this.$t('promo'),
        this.$t('order'),
        this.$t('rev'),
        this.$t('subs'),
        this.$t('mydata'),
      ],
      category: [
        {
          link: "/",
          title: this.$t("main")
        }, 
        {
          link: "",
          title: this.$t("acc")
        }
      ],
    };
  },
  setup() {
    const isOpen = ref(false);
    return {
      isOpen,
    };
  },
  created() {
    this.onSubmit();
  },
  methods: {
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    toBasket() {
      this.$router.push("/basket");
    },
    updateUser() {
      this.user = {
        name: this.userData.name,
        sname: this.userData.sname,
        lname: this.userData.lname,
        phone: this.userData.phone_number,
        email: this.userData.email,
        bday: this.userData.bday,
      }
      axios
      .post("auth/user-update", this.user, {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(this.$t('saveSuccess'))
          this.disabled = true
          this.SET_INFO(res.data);
          this.userData = res.data
        }
      })
      .catch((err) => {
          console.log(err);
        });
    },
    changePassword() {
      this.form = {
        old_password: this.old_password,
        new_password: this.new_password
      }
      axios
      .post('auth/password-update', this.form, {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => {
        if (res.status === 202) {
          toast.success(this.$t('passSave'));
          this.isOpen = false;
          this.new_password = "";
          this.old_password = "";
        }
      })
      .catch((err) => {
        toast.error(this.$t('errPass'));
          console.log(err);
        });
    },
    onSubmit() {
      axios
        .post("auth/me", {}, {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },

        })
        .then((res) => {
          if (res.status === 200) {
            this.userData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/account.scss";
</style>
