<template>
    <div class="order">
      
      <personal-tab
          :tabItems="tabItems"
          @setTab="selectNewsTab"
          :selected="selectedNewsTab"
        >
        </personal-tab>
        <div class="">
          <tab-block :isSelected="selectedNewsTab === this.$t('orders.cr')">
            <div class="local" v-if="order.orders && order?.orders.length > 0">
              <div class="local-card" v-for="(item, index) in order.orders" :key="index">
                <p class="fw600">{{ $t("orders.st") }} - <span>{{ item.status }}</span></p>
                <p class="fw600">{{ $t("orders.title") }} № {{ item.id }} от <span>{{ item.created_at }}</span></p>
                <p>{{ $t("orders.sum") }}: <span class="fw600"> {{ item.total_price }} , {{ item.pay_method }}</span></p>
                <p>{{ $t("orders.del") }}:<span class="fw600"> {{ $t("orders.exp") }}</span></p>
                <p>{{ $t("orders.prod") }}: <span class="fw600" v-for="(it, index) in item.products" :key="index">{{ it.product_name }}</span></p>
              </div>
            </div>
            <div class="non-order" v-if="order.orders && order?.orders.length <= 0">
              <img src="@/assets/images/basket-order.png" alt="">
              <div class="non-order__right">
                <p class="fw600 px16">{{ $t("orders.text") }}</p>
                <router-link :to="{path: `/catalog/palto-vesna-osen`}">
                  <button class="button-active">
                    {{ $t("orders.start") }}
                  </button>
                </router-link>
              </div>
            </div>
          </tab-block>
          <tab-block :isSelected="selectedNewsTab === this.$t('orders.all')"></tab-block>
        </div>
      <tab-block/>
    </div>
</template>

<script>
import personalTab from '../tab/orderTab.vue'
import TabBlock from '../tab/tabBlock.vue'
import axios from "axios"
export default {
  data() {
    return {
      selectedNewsTab: this.$t('orders.cr'),
      order: [],
      tabItems: [
        this.$t('orders.cr'),
        this.$t('orders.all')
      ],
    }
  },
  components: { personalTab, TabBlock },
  created() {
    this.getOrder()
  },
  methods: {
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    getOrder() {
      axios.post('auth/user-orders', {}, {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.order = res.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.local {
  margin-top: 25px;
  &-card {
    margin-bottom: 45px;
  }
  p {
    margin-bottom: 15px;
    font-size: 18px;
  }
}
.non {
  &-order {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    img {
      margin-right: 35px;
    }
    &__right {
      p {
        margin-bottom: 24px;
      }
      button {
        width: 170px;
      }
    }
  }
}
@media (max-width: 970px) {
  .non {
        &-order {
            align-items: flex-start;
        }
    }
  .order {
    margin-top: 40px;
  }
}
</style>