<template>
  <div class="container">
    <div class="err-wrap">
      <div class="err">
        <p class="err-num px24 fw500">404</p>
        <p class="err-text">This page could not be found.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.err {
  &-wrap {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  &-num {
    border-right: 1px solid #000;
    margin-right: 20px;
    padding: 10px 23px 10px 0;
  }
}
</style>
