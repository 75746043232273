<template>
  <div class="container" v-if="MAIN_PAGE.products">
    <div class="arrival">
      <p class="arrival-title section-title">{{ $t("arrival") }}</p>
      <!-- {{ MAIN_PAGE.products.new }} -->
      <swiper
          :slidesPerView="4"
          :spaceBetween="30"
          class="mySwiper"
          :breakpoints="breakpoints"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }"
        :loop="true"
          v-if="MAIN_PAGE.products.new"
      >
          <swiper-slide v-for="(item, index) in MAIN_PAGE.products.new" :key="index">
            <Card
            :PRODUCT_CARD="item" 
            />
          </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import Card from './Card.vue';
import { mapState } from 'vuex'
import { Autoplay } from 'swiper';
// import 'swiper/css/pagination';
// import { Pagination } from 'swiper';
export default {
    components: {
      Swiper,
      SwiperSlide,
      Card
    },
    props: ["MAIN_PAGE"],
    data() {
      return {
        breakpoints: {
          970: {
            spaceBetween: 24,
            slidesPerView: 4,
          },
          0: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
        },
      }
    },
    setup() {
      return {
        modules: [Autoplay],
      };
    },
    computed: {
      ...mapState(['cdn'])
    },
}
</script>

<style lang="scss" scoped>
.mySwiper {
  padding: 15px 10px;
}
.arrival {
  margin-top: 90px;
  position: relative;
  &-title {
    margin-bottom: 20px;
  }
  .number {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: -25px;
    img {
      height: 230px;
      z-index: 11;
    }
    p {
      color: #C41230;
      font-size: 64px;
      font-weight: 900;
      line-height: 65px;
    }
  }
}
@media (max-width: 970px) {
  .arrival {
    margin-top: 40px;
    &-title {
      margin: 0px;
    }
  }
  .number {
    top: -10px !important;
  }
}
</style>