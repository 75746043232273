<template>
    <div class="choose" v-if="MAIN_PAGE?.whyUsItems">
        <div class="container">
            <p class="choose-title section-title">{{ $t("whywe") }}</p>
            <div class="choose-wrap">
                <div class="choose-wrap__card" v-for="(item, index) in MAIN_PAGE?.whyUsItems" :key="index">
                    <div class="choose-wrap__card-top" @click="isClicked(item)">
                        <p class="px20 fw700 black" v-html="item.title"></p>
                        <img :class="{'rotate' : item.isOpen}" class="m_none" src="@/assets/images/main/red-arr.png" alt="">
                        <img :class="{'rotate' : item.isOpen}" class="d_none" src="@/assets/images/main/red-arr2.png" alt="">
                    </div>
                    <Transition>
                        <div class="choose-wrap__card-desc" v-show="item.isOpen">
                            <p v-html="item.content"></p>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["MAIN_PAGE"],
    data() {
        return {
            card: [
                {
                    title: "Международная доставка",
                    desc: "TERI SARAYI предоставляет международную доставку, что означает, что наши качественные товары доступны не только клиентам в Казахстане, но и за его пределами. Мы гарантируем безопасность и своевременную доставку наших товаров в любую точку мира.",
                    id: 1,
                    isOpen: false,
                },
                {
                    title: "Оплата после примерки",
                    desc: "TERI SARAYI предоставляет международную доставку, что означает, что наши качественные товары доступны не только клиентам в Казахстане, но и за его пределами. Мы гарантируем безопасность и своевременную доставку наших товаров в любую точку мира.",
                    id: 2,
                    isOpen: false,
                },
                {
                    title: "Легкий возврат",
                    desc: "TERI SARAYI предоставляет международную доставку, что означает, что наши качественные товары доступны не только клиентам в Казахстане, но и за его пределами. Мы гарантируем безопасность и своевременную доставку наших товаров в любую точку мира.",
                    id: 3,
                    isOpen: false,
                },
                {
                    title: "Опыт на рынке",
                    desc: "TERI SARAYI предоставляет международную доставку, что означает, что наши качественные товары доступны не только клиентам в Казахстане, но и за его пределами. Мы гарантируем безопасность и своевременную доставку наших товаров в любую точку мира.",
                    id: 4,
                    isOpen: false,
                },
            ]
        }
    },
    methods: {
        isClicked(item) {
            item.isOpen = !item.isOpen
        }
    }
}
</script>

<style scoped lang="scss">
.rotate {
    transform: rotate(-90deg);
}
.container {
    position: relative;
}
.number {
    display: flex;
    align-items: flex-start;
    position: absolute;
    bottom: -140px;
    right: 0;
    img {
      height: 230px;
      z-index: 11;
    }
    p {
      color: #C41230;
      font-size: 64px;
      font-weight: 900;
      line-height: 65px;
    }
}
.choose {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 81.25%, #D9D9D9 100%);
    // padding-bottom: 40px;
    &-title {
        margin-bottom: 65px;
    }
    &-wrap {
        background-image: url('@/assets/images/main/choose-back2.png');
        background-repeat: no-repeat;
        background-position-x: 92.5%;
        background-position-y: top;
        background-size: 29%;
        padding: 0 0 40px 0;
        min-height: 370px;
        &__card {
            width: 55%;
            padding: 12px 0;
            border-bottom: 1px solid #000;
            &-top {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                img {
                    width: 23px;
                    transition: all 0.4s;
                }
            }
            p {
                line-height: 24px;
            }
            &-desc {
                transition: all 0.4s;
                padding: 15px 0 0 0;
                p {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
}
@media (max-width: 970px) {
    .choose {
        background: none;
        &-title {
            margin-bottom: 30px;
        }
        &-wrap {
            background-image: none;
            min-height: 300px;
            &__card {
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.20);
                &-top {
                    p {
                        font-size: 14px;
                    }
                }
                &-desc {
                    p {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
    .number {
        top: 0;
    }
}
</style>