<template>
  <div class="price-filter">
    <label>{{ $t("priceRange") }}</label>
    <div class="range-slider">
      <div>
        <span>{{ minPrice }}</span>
      </div>

      <div>
        <input type="range" min="1000" max="500000" v-model="minPrice" @input="filterProducts">
        <input type="range" min="1000" max="500000" v-model="maxPrice" @input="filterProducts">
      </div>
     <div>
      <span>{{ maxPrice }}</span>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      minPrice: 1000,
      maxPrice: 500000
    };
  },
  methods: {
    filterProducts() {
      console.log("Min Price:", this.minPrice);
      console.log("Max Price:", this.maxPrice);
      this.$emit('changePriceRange', { minPrice: this.minPrice, maxPrice: this.maxPrice });
    }
  }
};
</script>

<style scoped>
.price-filter {
  margin-bottom: 20px;
}

.range-slider {
  display: flex;
  justify-content: space-between;
}

.range-values {
  display: flex;
  justify-content: space-between;
}
</style>
