<template>
  <div class="">
    <Slider :MAIN_PAGE="MAIN_PAGE"/>
    <nav-bar/>
    <Arrival :MAIN_PAGE="MAIN_PAGE"/>
    <hits :MAIN_PAGE="MAIN_PAGE"/>
    <Stock :MAIN_PAGE="MAIN_PAGE"/>
    <Choose :MAIN_PAGE="MAIN_PAGE"/>
    <Review :MAIN_PAGE="MAIN_PAGE"/>
  </div>
</template>

<script>
import Slider from '@/components/main/Slider.vue';
import Arrival from '@/components/main/Arrival.vue';
import hits from '@/components/main/hits.vue';
import Stock from '@/components/main/Stock.vue';
import NavBar from '@/components/ui/navBar.vue';
import Choose from '@/components/main/Choose.vue';
import Review from '@/components/main/Review.vue';
import {mapActions, mapGetters} from 'vuex'
export default {
  components: {Slider, Arrival, hits, Stock, NavBar, Choose, Review},
  mounted() {
    this.GET_MAIN_PAGE();
  },
  methods: {
    ...mapActions(["GET_MAIN_PAGE"])
  },
  computed: {
    ...mapGetters(['MAIN_PAGE']),
  },
}
</script>

<style>

</style>