<template>
  <div class="container">
    <nav-bar/>
    <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
    <div class="catalog">
      <Category class="category m_none"/>
      <div class="catalog-left" v-if="PRODUCT_BY_CATEGORY.products">
        <div class="catalog-left__top">
          <Category class="category d_none"/>
          <Filters @changeFilters="emitFilters" class="filter"/>
          <div class="range-slider">
            <div class="range-values">
              <p class="m-none">{{ minPrice }}</p>
              <p class="desktop-none">Мин. цена</p>
              <input type="number" v-model.number="minPrice" class="price-input desktop-none">
              <input class="slider m-none" type="range" min="1000" max="2000000" step="10" v-model.number="minPrice">
            </div>
            <div class="range-values">
              <p class="m-none">{{ maxPrice }}</p>
              <p class="desktop-none">Мах. цена</p>
              <input type="number" v-model.number="maxPrice" class="price-input desktop-none">
              <input class="slider m-none" type="range" min="1000" max="2000000" step="10" v-model.number="maxPrice">
            </div>
          </div>
          <div class="sort m_none">
            <!-- <button>{{ $t("sort") }}</button> -->
            <select name="" id="" v-model="order_by" @change="changeSort($event)">
              <option disabled>{{ $t("sort") }}</option>
              <option selected value="asc">{{ $t("ascSort") }}</option>
              <option value="desc">{{ $t("descSort") }}</option>
            </select>
          </div>
        </div>
        <div class="catalog-left__wrap">
          <div class="catalog-left__cards">
            <Card
                class="cards"
                v-for="(item, index) in  sortedProducts"
                :PRODUCT_CARD="item"
                :key="index"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="paginate-wrap" v-if="PRODUCT_BY_CATEGORY?.products?.last_page > 1">
      <paginate
          v-model="page"
          :page-count="PRODUCT_BY_CATEGORY.products.last_page"
          :click-handler="clickCallback"
          class="paginate"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'paginate'"
          :active-class="'paginate-active'">
      </paginate>
    </div>
    <div class="rec" v-if="PRODUCT_BY_CATEGORY.recomend_products">
      <p
          class="rec-title px28-500"
          v-if="PRODUCT_BY_CATEGORY.recomend_products.length > 0"
      >
        {{ $t("rec") }}
      </p>
      <swiper
          :slidesPerView="4"
          :spaceBetween="30"
          class="mySwiper"
          :breakpoints="breakpoints"
      >
        <swiper-slide
            v-for="(item, index) in PRODUCT_BY_CATEGORY.recomend_products"
            :key="index"
        >
          <Card :PRODUCT_CARD="item"/>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Category from "@/components/product/Category.vue";
import Filters from "@/components/product/Filters.vue";
import PriceFilter from "@/components/product/PriceFilter.vue";
import Card from "@/components/main/Card.vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";
import {mapState, mapActions, mapGetters} from "vuex";
import navBar from "@/components/ui/navBar.vue";
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Paginate from "vuejs-paginate-next";

export default {
  components: {
    PriceFilter,
    Category,
    Filters,
    Card,
    Swiper,
    SwiperSlide,
    navBar,
    BreadCrumb,
    paginate: Paginate,
  },
  data() {
    return {
      page: this.$route.query.page || 1,
      order_by: "asc",
      breakpoints: {
        970: {
          spaceBetween: 10,
          slidesPerView: 4,
        },
        0: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
      },
      filtersValue: [],
      category: [
        {
          link: "/",
          title: this.$t("main")
        },
        {
          link: "",
          title: this.$t("catalog")
        }
      ],
      minPrice: 1000,
      maxPrice: 2000000,
      selected: null,
      sortedProducts: null,
    };
  },
  created() {
    this.fetchProducts();
  },
  mounted: function () {
    this.setRangeSlider();
  },
  methods: {
    ...mapActions(["GET_PRODUCT_BY_CATEGORY"]),
    clickCallback(pageNum) {
      this.page = pageNum;
      this.$router.push({path: `/catalog/${this.$route.params.slug}`, query: {page: this.page}})
      this.fetchProducts();
    },
    changeSort(e) {
      this.order_by = e.target.value;
      this.fetchProducts()
    },
    emitFilters(value) {
      this.filtersValue = value;
      this.fetchProducts();
    },
    fetchProducts() {
      this.GET_PRODUCT_BY_CATEGORY({
        slug: this.$route.params.slug,
        filter_id: this.filtersValue,
        order_by: this.order_by,
        page: this.$route.query.page || '1'
      });
    },
    setRangeSlider() {
      if (this.minPrice > this.maxPrice) {
        let tmp = this.maxPrice;
        this.maxPrice = this.minPrice;
        this.minPrice = tmp;
      }
      this.sortByPrice();
    },
    sortByPrice() {
      if (this.PRODUCT_BY_CATEGORY && this.PRODUCT_BY_CATEGORY.products && this.PRODUCT_BY_CATEGORY.products.data) {
        let vm = this;
        this.sortedProducts = this.PRODUCT_BY_CATEGORY.products.data.filter(function (item) {
          return item.current_price >= vm.minPrice && item.current_price <= vm.maxPrice;
        });
      }
    },
    // changeCategory() {
    //   this.category.push({title: this.PRODUCT_BY_CATEGORY?.category?.title, link: ""});
    // }
  },
  computed: {
    ...mapGetters(["PRODUCT_BY_CATEGORY"]),
    ...mapState(["cdn"]),
  },
  watch: {
    $route() {
      this.filtersValue = [];
      this.fetchProducts();
      // this.changeCategory();
    },
    minPrice(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortByPrice();
      }
    },
    maxPrice(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortByPrice();
      }
    },
    PRODUCT_BY_CATEGORY() {
      if (!this.sortedProducts || this.sortedProducts) {
        this.sortedProducts = this.PRODUCT_BY_CATEGORY.products.data
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #282828;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #282828;
  cursor: pointer;
}

.range-values:first-child {
  margin-left: 33px;
  margin-top: 20px;
}

.range-values:last-child {
  margin-left: 100px;
  margin-top: 20px;
}

.catalog {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 70px;

  .desktop-none {
    display: none;
  }

  &-left {
    width: 79%;

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .range-slider {
        width: 200px;
        margin: auto 16px;
        text-align: center;
        position: relative;
        display: flex;
        justify-content: space-between;
      }

      .range-slider input[type=range] {
        position: absolute;
        left: 40px;
        top: -10px;
      }

      input[type=range]::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        top: 2px;
        margin-top: -7px;
      }

      @media screen and (max-width: 425px) {
        .range-slider input[type=range] {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-200px, 45px);
        }

        .slider {
          width: 100px;
        }

        .range-values:first-child {
          position: absolute;
          left: -290px;
          top: 20px;
        }

        .range-values:last-child {
          position: absolute;
          left: -200px;
          top: 20px;
        }
      }

      .sort {
        select {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border: 1px solid #dcdcdc;
          font-size: 16px;
          font-weight: 500;
          position: relative;
          padding: 22px;
        }
      }
    }

    &__cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 40px 0;

      .cards {
        width: 32%;
        margin-bottom: 2%;
      }
    }
  }

  .category {
    width: 20%;
    margin-top: 67px;
  }
}

.rec {
  margin: 30px 0 50px 0;

  p {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 970px) {
  .catalog {
    .range-slider {
      margin-bottom: -80px;
      margin-left: -250px;
    }
    &-left {
      width: 100%;

      &__cards {
        .cards {
          width: 48%;
        }
      }

      &__top {
        width: 100%;
        justify-content: flex-start;
        padding-bottom: 4rem;

        .category {
          height: 63px;
          width: 150px;
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .catalog {
    .desktop-none {
      display: block;
    }
    .m-none {
      display: none;
    }
    .range-slider {
      margin-bottom: 0;
      margin-left: -30px;
    }
  }
}
</style>
