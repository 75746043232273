<template>
  <div class="container">
    <div class="pages" v-if="PAGE.pages">
      <div class="pages-card" v-if="PAGE.pages.image">
        <img class="pages-img" :src="cdn + PAGE.pages.image" alt="" />
        <div class="pages-text">
          <p v-if="PAGE.pages.title" class="pages-text__title px28-500" v-html="PAGE.pages.title"></p>
          <p v-if="PAGE.pages.description" class="px16-400" v-html="PAGE.pages.description"></p>
        </div>
      </div>
      <div v-else class="">
        <div class="pages-par">
          <p v-if="PAGE.pages.title" class="pages-text__title px28-500" v-html="PAGE.pages.title"></p>
          <p v-if="PAGE.pages.description" class="px16-400" v-html="PAGE.pages.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
export default {
  mounted() {
    this.GET_PAGE(this.$route.params.slug);
  },
  methods: {
    ...mapActions(["GET_PAGE"])
  },
  computed: {
    ...mapGetters(['PAGE']),
    ...mapState(['cdn']),
  },
  watch: {
    $route() {
      this.GET_PAGE(this.$route.params.slug);
    }
  }
};
</script>

<style lang="scss" scoped>
.pages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &-img {
    width: 40%;
    height: 40vw;
    max-height: 800px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  &-par {
    width: 60%;
    margin: 0 auto;
  }
  &-text {
    width: 58%;
    &__title {
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 960px) {
  .pages {
    &-card {
      flex-direction: column;
    }
    &-img {
      width: 100%;
      height: 100vw;
    }
    &-text {
      width: 100%;
    }
  }
}
</style>
