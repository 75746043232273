<template>
    <div class="tab-items m-none">
      <ul class="tab-list">
        <li v-for="tab in tabItems" :key="tab" class="tab-item" :class="{ activeItem: tab === selected }">
          <div
            :class="{ active: tab === selected }"
            class="tab-link tab-title"
            @click="setTab(tab)"
          >
            {{ tab }}
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      tabItems: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
        required: true,
      },
    },
    methods: {
      setTab(tab) {
        this.$emit("setTab", tab);
      },
    },
  };
  </script>

<style lang="scss" scoped>
.active {
    color: #000;
}
.activeItem {
}
.tab {
    &-list {
        display: flex;
    }
    &-item {
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
        color: #77757f;
        padding-right: 25px;
        &:hover {
            color: #000;
        }
    }
    &-link {
        cursor: pointer;
    }
}
</style>