<template>
  <div v-if="PRODUCT_CARD" class="card">
    <div class="card-top">
      <div @click="addFavorite(PRODUCT_CARD)" class="heart">
        <i :class="favoriteActive ? 'fa-heart active' : 'fa-heart-o'" class="fa"></i>
      </div>
    </div>
    <!-- ${this.brand_slug} -->
    <router-link :to="{ path: `/product/${PRODUCT_CARD.slug}` }" class="card-img">
      <img :src="cdn + PRODUCT_CARD?.main_image" alt="" />
    </router-link>
    <div class="card-text">
      <!-- <p class="card-text__title px20-400" v-html="PRODUCT_CARD?.brand_title"></p> -->
      <p class="card-text__name px20-400" v-html="PRODUCT_CARD?.title"></p>
    </div>
    <div class="card-text__price px16-400 price" v-if="PRODUCT_CARD?.current_price">
      {{ formatPrice(PRODUCT_CARD?.current_price) }}
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      product: [],
      isActive: false,
      favoriteActive: false,
    };
  },
  props: [
    "PRODUCT_CARD",
  ],
  mounted() {
    // this.GET_PRODUCT_CARD({ slug: this.$route.params.slug });
    this.inTheFavourite();
  },
  methods: {
    ...mapActions(["LOCALSTORAGE_PRODUCT", "GET_PRODUCT_CARD", "CHECK_FAVOURITE_LIST",]),
    ...mapMutations(["ADD_FAVORITES"]),
    async toLocal() {
      // console.log("a", this.products);
      await this.LOCALSTORAGE_PRODUCT(this.products);
    },
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    addFavorite(value) {
      // console.log("value",value);
      this.favoriteActive = !this.favoriteActive;
      this.ADD_FAVORITES(value);
    },
    inTheFavourite() {
      if (this.favoriteList && this.PRODUCT_CARD) {
        let findCart = this.favoriteList.find(
          (i) => i.id === this.PRODUCT_CARD?.id
        );
        if (findCart) {
          this.favoriteActive = true;
        } else {
          this.favoriteActive = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      product_cards : "PRODUCT_CARD", favoriteList: "GET_FAVOURITE_LIST",
    }),
    ...mapState(["cdn"]),
    
  },
  watch: {
    $route() {
      // this.GET_PRODUCT_CARD({ slug: this.$route.params.slug });
    },
    PRODUCT_CARD() {
      this.inTheFavourite();
    },
    favoriteList() {
      this.inTheFavourite();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 700px;
  transition: all ease 0.4s;
  border: 1px solid #dddae4;
  a {
    padding: 54px 54px 0 54px;
  }
  &-top {
    .heart {
      position: absolute;
      width: 28px;
      padding: 10px 0 0 10px;
      z-index: 11;
      left: 0%;
    }
  }
  &-img {
    img {
      width: 100%;
      border-radius: 20px;
      height: 365px;
      object-fit: cover;
    }
  }
  &-text {
    padding: 12px 10px;
    text-align: left;
    height: 72px;
    &__price {
      padding: 0 10px;
      margin-bottom: 40px;
      text-align: left;
    }
    &__name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  &:hover {
    transform: scale(1.05);
    transition: all ease 0.4s;
  }
}
@media (max-width: 1440px) {
  .card {
    &-img {
      img {
        height: 250px;
      }
    }
  }
}
@media (max-width: 970px) {
  .card {
    height: auto;
    a {
      padding: 10px;
    }
    &-top {
      img {
        padding: 5px 0 0 5px;
        width: 20px;
      }
    }
    &-img {
      img {
        max-height: none;
        height: 200px;
      }
    }
    &-text {
      height: 50px;
      &__name {
        padding: 0;
        font-size: 12px;
        font-weight: 600;
      }
      &__price {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 18px;
      }
    }
  }
}
</style>
