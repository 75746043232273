<template>
  <div class="container" v-if="MAIN_PAGE?.products">
    <div class="hits">
      <p class="arrival-title section-title">{{ $t("hits") }}</p>
      <swiper
        :slidesPerView="4"
        :spaceBetween="30"
        :modules="modules"
        :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }"
        :loop="true"
        class="mySwiper"
        :breakpoints="breakpoints"
        v-if="MAIN_PAGE.products.best"
      >
        <swiper-slide v-for="(item, index) in MAIN_PAGE.products.best" :key="index">
          <Card 
          :PRODUCT_CARD="item" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay } from 'swiper';
import Card from "./Card.vue";
import { mapState } from "vuex"
// import 'swiper/css/pagination';
// import { Pagination } from 'swiper';
export default {
  components: {
    Swiper,
    SwiperSlide,
    Card,
  },
  props: ["MAIN_PAGE"],
  data() {
    return {
      breakpoints: {
          970: {
            spaceBetween: 24,
            slidesPerView: 4,
          },
          0: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
        },
    }
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  computed: {
    ...mapState(['cdn'])
  },
};
</script>

<style lang="scss" scoped>
.mySwiper {
  padding: 15px 10px;
}
.arrival-title {
  margin-bottom: 30px;
}
.hits {
  position: relative;
  margin-top: 110px;
}
.number {
    position: absolute;
    top: -45px;
    right: 0;
    p {
      color: #C41230;
      font-size: 64px;
      font-weight: 900;
      line-height: 65px;
    }
  }
@media (max-width: 970px) {
  .arrival-title {
    margin: 0px;
  }
  .hits {
    margin-top: 30px;
  }
  .number {
    top: 0 !important;
  }
}
</style>
