<template>
    <div class="rev" v-if="rev">
        <div class="rev-title px16 fw500">Отзывы</div>
        <!-- {{ rev?.reviews }} -->
        <div class="rev-wrap" v-if="rev.reviews && rev?.reviews.length < 1">
            <img src="@/assets/images/rev.png" alt="">
            <div class="rev-wrap__text">
                <p class="px18 fw600">У вас нет отзывов</p>
                <p class="px16 fw500">Оставьте отзыв о заказанном товаре, и другие <br> покупатели будут вам благодарны.</p>
            </div>
        </div>
        <div class="rev-card" v-else>
                <div v-for="(item, index) in rev?.reviews" :key="index" class="rev-card__box">
                    <div class="" v-if="item?.status_name && item?.status_name !== 'declined'">
                        <p>{{ item.status }}</p>
                        <p class="px18 fw600" style="margin-top: 12px;">{{ item.product.name }}</p>
                        <star-rating
                            inactive-color="#989191"
                            active-color="#AB92E3"
                            v-model:rating="item.rating"
                            :increment="0.5"
                            >
                        </star-rating>
                        <p style="margin: 7px 0;">{{ item.created_at }}</p>
                        <p class="px16"><span class="fw600">Комментарий:</span> {{ item.review }}</p>
                        <p style="display: none;">{{ review_id =  item.id}}</p>
                        <Modal :open="isOpen" @close="isOpen = !isOpen">
                            <div class="modal">
                                <p class="px18 fw500">Изменить отзыв</p>
                                <input v-model="review" type="text" placeholder="Напишите отзыв" class="input">
                                <star-rating
                                    class="modal-star"
                                    inactive-color="#989191"
                                    active-color="#AB92E3"
                                    v-model:rating="rating"
                                    :increment="0.5"
                                    >
                                </star-rating>
                                <button @click="updateReview" class="button-active">{{ $t("send") }}</button>
                            </div>
                        </Modal>
                        <div class="rev-btns" v-if="item?.status_name && item?.status_name == 'in_process'">
                            <button @click="isOpen = true" class="button-active">Редактировать</button>
                            <button @click="deleteReview" class="button">{{ $t("delete") }}</button>
                        </div>
                    </div>
                </div>
        </div>
        <!-- <Modal :open="isOpen" @close="isOpen = !isOpen">
            <div class="modal">
                <p class="px18 fw500">Изменить отзыв</p>
                <input v-model="review" type="text" placeholder="Напишите отзыв" class="input">
                <star-rating
                    class="modal-star"
                    inactive-color="#989191"
                    active-color="#AB92E3"
                    v-model:rating="rating"
                    :increment="0.5"
                    >
                </star-rating>
                <button @click="updateReview" class="button-active">Отправить</button>
            </div>
        </Modal> -->
    </div>
</template>

<script>
import axios from "axios"
import StarRating from "vue-star-rating";
import Modal from "../modal/Modal.vue";
import {ref} from 'vue'
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
    components: {StarRating, Modal},
    data() {
        return {
            rev: [],
            resetableRating: 2,
            currentRating: "No Rating",
            mouseOverRating: null,
        }
    },
    created() {
        this.setReview()
    },
    setup() {
        const isOpen = ref(false);
        return {
        isOpen,
        };
    },
    methods: {
        async setReview() {
           await axios
            .post("auth/user-reviews", {}, {
            headers: {
                Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },

            })
            .then((res) => {
                console.log('res1',res);
                if (res.status === 200) {
                    console.log('res2',res);
                    this.rev = res.data
                } 
                
            })
            .catch((err) => {
                console.log(err);
            });
        },
        showCurrentRating(rating) {
        this.currentSelectedRating =
            rating === 0
            ? this.currentSelectedRating
            : "Click to select " + rating + " stars";
        },
        setCurrentSelectedRating(rating) {
            this.currentSelectedRating = "You have Selected: " + rating + " stars";
        },
        deleteReview() {
            this.review = {
                review_id: this.review_id
            };
            axios
            .post("auth/delete-review", this.review, {
                headers: {
                    Authorization: localStorage.getItem("access_token")
                    ? `Bearer ${localStorage.getItem("access_token")}`
                    : null,
                },

            })
            .then((res) => {
                console.log('res1',res);
                this.setReview()
            })
            .catch((err) => {
                console.log(err);
            });
        },
        updateReview() {
            this.form = {
                review: this.review,
                review_id: this.review_id,
                rating: this.rating
            },
            axios
            .post("auth/update-review", this.form, {
            headers: {
                Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },

            })
            .then((res) => {
                console.log('res1',res);
                if (res.status === 202) {
                    toast.success("Ваш отзыв успешно изменен!")
                    this.setReview();
                    this.isOpen = false
                } 
                
            })
            .catch((err) => {
                console.log(err);
            });
        }

    },
    computed: {
        currentRatingText() {
            return this.rating
                ? "You have selected " + this.rating + " stars"
                : "No rating selected";
        },
        mouseOverRatingText() {
            return this.mouseOverRating
                ? "Click to select " + this.mouseOverRating + " stars"
                : "No Rating";
        },
    },
}
</script>

<style lang="scss" scoped>
.rev {
    &-title {
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    &-wrap {
        display: flex;
        justify-content: center;
        img {
            margin-right: 30px;
        }
        &__text {
            p {
                margin-bottom: 10px;
            }
        }
    }
    &-card {
        &__box {
            margin-bottom: 45px;
        }
    }
    &-btns {
        margin-top: 15px;
        button {
            width: 200px;
            margin-right: 17px;
        }
        .button {
            background: #FFFFFF;
            border: 1px solid #1A0145;
            color: #1A0145;
            padding: 16px 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            transition: 0.4s;
            &:hover {
                background-color: #1A0145;
                color: #FFFFFF;
                transition: 0.4s;
            }
        }
    }
}
.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
        width: 85%;
        margin: 30px 0;
    }
    button {
        width: 200px;
    }
    &-star {
        margin-bottom: 30px;
    }
}
@media (max-width: 970px) {
  .rev{
    &-title {
        display: none;
    }
    &-wrap {
            align-items: flex-start;
            margin-top: 40px;
        }
    }
}
</style>