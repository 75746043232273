<template>
  <div>
    <div class="filter m_none">
      <p>{{ $t("filter") }}</p>
      <button v-for="(item, index) in PRODUCT_BY_CATEGORY.filters" :key="index" @click="(IsActiveButton(index)), (selectedButton = item), (isVisible = true)"  :class="{ activeButton: index === IsActive }">{{ item.title }} 
        <img class="arr-img" :class="{'rotate-arr' : index === IsActive}" src="@/assets/images/arrow_down.png" alt="">
      </button>
      <div class="" v-if="isVisible">
        <div class="filter-block" v-if="selectedButton" >
          <div class="filter-block__card" v-for="(item, index) in selectedButton.filter_items" :key="index">
              <input v-model="filters" :value="item.id" type="checkbox" name="">
              <label class="px16 fw500">{{ item.title }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-mobile d_none">
      <div class="filter-mobile__top d_none">
        <p class="filter-mobile__top-title" @click="isFilter = true">{{ $t("filter") }}</p>
        <Transition>
        <div class="filter-mobile__links" v-if="isFilter">
          <img class="filter-mobile__img" @click="isFilter = false" src="@/assets/images/close.png" alt="">
          <p class="px16 fw600 filter-mobile__name">{{ $t("filter") }}</p>
          <div class="filter-mobile__cards">
            <ul class="">
              <li
                v-for="(item, index) in PRODUCT_BY_CATEGORY.filters"
                :key="index"
                class="category-box__li"
              >
                <a
                  class="px16 fw500 gray"
                  @click="activeSub(index)"
                  >{{ item.title }}
                  <img
                    v-if="item.filter_items.length > 0"
                    src="@/assets/images/arrow_down.png"
                    alt=""
                    @click="activeSub"
                  />
                </a>
                <div class="filter-sub" v-if="isSub == index">
                  <ul>
                    <li v-for="(filter, index) in item.filter_items" :key="index">
                      <input v-model="filters" :value="filter.id" type="checkbox" @click="closeFilter">
                      <label class="px16 fw500">{{ filter.title }}</label>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Transition>
      </div>
    </div>
    <div class="visible" @click="clickOutside" :class="{visibleActive: isVisible }"></div>
    <div class="visible" @click="(isFilter = false)" :class="{visibleActive: isFilter }"></div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
export default {
  data() {
    return {
      IsActive: false,
      filters: [],
      isVisible: false,
      isFilter: false,
      isSub: false
    }
  },
  emits: ['changeFilters'],
  mounted() {
  },
  methods: {
    ...mapActions(['GET_PRODUCT_BY_CATEGORY']),
    IsActiveButton(index) {
      this.IsActive = index;
    },
    closeFilter() {
      this.isFilter = false;
    },
    activeSub(index) {
      this.isSub = index;
    },
    clickOutside() {
      this.isVisible = false;
      this.IsActive = false;
    }
  },
  watch: {
    filters() {
      this.$emit('changeFilters', this.filters)
    }
  },
  computed: {
    ...mapGetters(['PRODUCT_BY_CATEGORY']),
    ...mapState(['cdn'])
  }
};
</script>

<style lang="scss" scoped>
.visibleActive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.arr-img {
  transition: all 0.4s ease;
}
.rotate-arr {
  transform: rotate(180deg);
}
.activeButton {
  background-color: #C4C4C4 !important;
}
.filter {
  display: flex;
  position: relative;
  p,
  button {
    text-align: center;
    border: 1px solid #dcdcdc;
    font-size: 16px;
    font-weight: 500;
    padding: 22px;
    background: #fff;
  }
  button {
    cursor: pointer;
  }
  &-block {
    position: absolute;
    top: 110%;
    flex-direction: row;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    background: #FFFFFF;
    box-shadow: 19px 16px 27px rgba(0, 0, 0, 0.08);
    padding: 20px;
    z-index: 900;
    max-height: 500px;
    width: 800px;
    max-width: 100vw;
    left: 0;
    overflow-y: scroll;
    &__card {
      width: 20%;
      height: 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      label {
        margin-left: 5px;
      }
      input {
        width: 20px;
      }
    }
  }
}
@media (max-width: 970px) {
  .filter {
    position: inherit;
    &-mobile {
      &__name {
        border: none !important;
      }
      &__top {
        width: 150px;
        height: 60px;
        border: 1px solid #dcdcdc;
        background-color: #fff;
        // position: relative;
        &-title {
          text-align: center;
          padding: 18px !important;
          border: none !important;
          color: #000;
          font-weight: 400 !important;
        }
      }
      &__links {
        position: absolute;
        top: 30%;
        left: 8px;
        right: 0;
        width: 96%;
        padding: 4px;
        background-color: #fff;
        border: 1px solid #dcdcdc;
        z-index: 111;
        p {
          margin-left: 15px;
          text-transform: uppercase;
          padding: 10px 0;
        }
      }
      &__img {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      &__cards {
        li {
          display: flex;
          flex-direction: column;
          padding: 3px 0;
          margin: 10px 15px;
          a {
            color: #000;
          }
        }
      }
    }
  }
  .filter {
    &-sub {
      ul {
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          label {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
