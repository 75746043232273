<template>
  <div class="container">
    <nav-bar />
    <div class="bread-crumb" style="display: flex;">
      <bread-crumb :crumbs="category" @selected="selected"></bread-crumb>
      <p>-</p>
      <p style="font-size: 16px; line-height: 20px; font-weight: 500;" v-if="PRODUCT_CARD.product" v-html="PRODUCT_CARD.product.title"></p>
    </div>
    <div class="product" v-if="PRODUCT_CARD.product">
      <div class="product-wrap">
        <div class="product-left">
          <div class="product-right__card-top d_none">
            <p class="product-right__card-title px28-500" v-html="PRODUCT_CARD.product.title">
            </p>
            <div @click="addFavorite(PRODUCT_CARD.product)" class="heart">
              <i
                :class="favoriteActive ? 'fa-heart active' : 'fa-heart-o'"
                class="fa"
              ></i>
            </div>
          </div>
          <div class="prices d_none">
            <p class="price px32-700">
            {{ formatPrice(PRODUCT_CARD.product.current_price) }}
            </p>
            <p class="old-price px24 fw700" v-if="PRODUCT_CARD.product.current_price !== PRODUCT_CARD.product.price">
            {{ formatPrice(PRODUCT_CARD.product.price) }}
            </p>
          </div>
          <div class="product-left__swiper">
            <swiper
              :slidesPerView="1"
              :spaceBetween="30"
              :modules="modules"
              :navigation="navigation"
              class="mySwiper"
              @swiper="onSwiper"
            >
              <swiper-slide
                v-for="(item, index) in PRODUCT_CARD.product.product_images"
                :key="index"
                v-show="PRODUCT_CARD?.product?.product_images?.length > 0"
                v-viewer
              >
                <img :src="cdn + item.image" alt="" />
              </swiper-slide>
              <img class="non-image" v-show="PRODUCT_CARD?.product?.product_images?.length == 0" src="@/assets/images/not-found.webp" alt="">
            </swiper>
            <div class="nav">
              <div class="swiper-button-prev">
                <img src="@/assets/images/arrow_left.svg" alt="" />
              </div>
              <div class="swiper-button-next">
                <img src="@/assets/images/arrow_right.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="product-right" v-if="PRODUCT_CARD.product">
          <div class="product-right__wrap">
            <div class="product-right__card">
              <div class="product-right__card-top m_none">
                <p class="product-right__card-title px28-500" v-html="PRODUCT_CARD.product.title">
                </p>
                <div @click="addFavorite(PRODUCT_CARD.product)" class="heart">
                  <i
                    :class="favoriteActive ? 'fa-heart active' : 'fa-heart-o'"
                    class="fa"
                  ></i>
                </div>
              </div>
              <div class="prices m_none">
                <p class="price px32-700">
                {{ formatPrice(PRODUCT_CARD.product.current_price) }}
                </p>
                <p class="old-price px24 fw700" v-if="PRODUCT_CARD.product.current_price !== PRODUCT_CARD.product.price">
                {{ formatPrice(PRODUCT_CARD.product.price) }}
                </p>
              </div>
              <div class="color">
                <span style="color: #555555">{{ $t('color') }}:</span>
                <button
                  v-for="(item, index) in PRODUCT_CARD.colors"
                  :key="index"
                  :style="'background:' + item.code"
                  @click="activeColor(item, index)"
                  :class="{ activecolor: index === isActive }"
                ></button>
              </div>
              <div class="piece">
                <span style="color: #555555">{{ $t("piece") }}:</span>
                <div class="piece-btn" :class="{'disabled-count' : activeCart}">
                  <button
                    style="font-size: 20px; line-height: 10px"
                    :disabled="disableMinus || activeCart"
                    @click="minusValue(PRODUCT_CARD.product)"
                  >
                    -
                  </button>
                  <button class="price">{{ count }}</button>
                  <button
                    style="font-size: 20px; line-height: 10px"
                    :disabled="disablePlus || activeCart"
                    @click="plusValue(PRODUCT_CARD.product)"
                  >
                    +
                  </button>
                </div>
              </div>
              <!-- <div class="none-product" v-if="this.selectedQuantity">
                <p class="" v-if="this.selectedQuantity <= this.count" style="color: red; font-weight: 500; margin-top: 20px;">В складе осталось {{ selectedQuantity }} товаров по выбранным категориям(цвет, размер, штук)</p>
              </div> -->
              <div class="stock">
                <p style="color: #555555">{{ $t("instock") }}:</p>
                <select name="" id="" class="select input" @change="onHandleChange($event)">
                  <option selected disabled value="">{{ $t('chooseSize') }}</option>
                  <option
                    v-for="(item, index) in PRODUCT_CARD?.size_type?.size_items"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
              <div class="product-right__card-btns">
                <!-- {{ activeCart }} -->
                <button
                  v-if="activeCart"
                  class="button-active"
                  @click="deleteFromBasket(PRODUCT_CARD.product)"
                >
                  {{ $t("deleteBask") }}
                </button>
                <button
                  v-else
                  class="button-active"
                  @click="addToCart(PRODUCT_CARD.product)"
                >
                  {{ $t("addBask") }}
                </button>
                <button class="button" @click="isOpen1 = true">
                  {{ $t("buyClick") }}
                </button>
              </div>
              <div v-if="showSize" class="size-img">
                <img :src="cdn + PRODUCT_CARD.product.table_size" alt="" />
              </div>
              <div
                v-if="showSize"
                @click="showSize = false"
                class="visible"
              ></div>
            </div>
          </div>
          <div class="product-acc">
            <div class="product-acc__title" v-if="PRODUCT_CARD.product">
              <div class="faq__item" @click="active_answer = answer[0].id">
                <div
                  class="faq__item-title px-18"
                  :class="{ active_title: active_answer === answer[0].id }"
                  @click="(visibleFaq = true), (hideFaq = false)"
                >
                  <p>{{ $t("desc") }}</p>
                  <span
                    :class="{ active_answer: active_answer === answer[0].id }"
                  >
                    <img src="@/assets/images/hide_arrow_up.svg" alt="" />
                  </span>
                </div>
                <div
                  class="faq__visible"
                  @click="(hideFaq = true), (visibleFaq = false)"
                  :class="{ hideFaq: hideFaq }"
                  v-if="active_answer === answer[0].id"
                ></div>
                <div
                  :class="{ showFaq: visibleFaq, hideFaq: hideFaq }"
                  class="faq__item-text px16 fw400"
                  v-show="active_answer === answer[0].id"
                  v-html="PRODUCT_CARD.product.description"
                ></div>
              </div>
            </div>
            <div class="product-acc__title" v-if="PRODUCT_CARD.product">
              <div class="faq__item" @click="active_answer = answer[1].id">
                <div
                  class="faq__item-title px-18"
                  :class="{ active_title: active_answer === answer[1].id }"
                  @click="(visibleFaq = true), (hideFaq = false)"
                >
                  <p>{{ $t("care1") }}</p>
                  <span
                    :class="{ active_answer: active_answer === answer[1].id }"
                  >
                    <img src="@/assets/images/hide_arrow_up.svg" alt="" />
                  </span>
                </div>
                <div
                  class="faq__visible"
                  @click="(hideFaq = true), (visibleFaq = false)"
                  :class="{ hideFaq: hideFaq }"
                  v-if="active_answer === answer[1].id"
                ></div>
                <div
                  :class="{ showFaq: visibleFaq, hideFaq: hideFaq }"
                  class="faq__item-text px16 fw400"
                  v-show="active_answer === answer[1].id"
                  v-html="PRODUCT_CARD.product.specifications"
                ></div>
              </div>
            </div>
            <div class="product-acc__title" v-if="PRODUCT_CARD.delivery">
              <div class="faq__item" @click="active_answer = answer[2].id">
                <div
                  class="faq__item-title px-18"
                  :class="{ active_title: active_answer === answer[2].id }"
                  @click="(visibleFaq = true), (hideFaq = false)"
                >
                  <p v-html="PRODUCT_CARD.delivery.title"></p>
                  <span
                    :class="{ active_answer: active_answer === answer[2].id }"
                  >
                    <img src="@/assets/images/hide_arrow_up.svg" alt="" />
                  </span>
                </div>
                <div
                  class="faq__visible"
                  @click="(hideFaq = true), (visibleFaq = false)"
                  :class="{ hideFaq: hideFaq }"
                  v-if="active_answer === answer[2].id"
                ></div>
                <div
                  :class="{ showFaq: visibleFaq, hideFaq: hideFaq }"
                  class="faq__item-text px16 fw400"
                  v-show="active_answer === answer[2].id"
                  v-html="PRODUCT_CARD.delivery.description"
                ></div>
              </div>
            </div>
            <div class="product-acc__title" v-if="PRODUCT_CARD.payment">
              <div class="faq__item" @click="active_answer = answer[3].id">
                <div
                  class="faq__item-title px-18"
                  :class="{ active_title: active_answer === answer[3].id }"
                  @click="(visibleFaq = true), (hideFaq = false)"
                >
                  <p>ОТЗЫВЫ</p>
                  <span
                    :class="{ active_answer: active_answer === answer[3].id }"
                  >
                    <img src="@/assets/images/hide_arrow_up.svg" alt="" />
                  </span>
                </div>
                <div
                  class="faq__visible"
                  @click="(hideFaq = true), (visibleFaq = false)"
                  :class="{ hideFaq: hideFaq }"
                  v-if="active_answer === answer[3].id"
                ></div>
                <div :class="{ showFaq: visibleFaq, hideFaq: hideFaq }"
                     v-show="active_answer === answer[3].id"
                     class="faq__item-text px16 fw400"
                >
                  <div class="feedback-form">
                    <h2>Оставьте свой отзыв</h2>
                    <textarea v-model="feedback" placeholder="Напишите..."></textarea>
                    <button @click="submitFeedback">Отправить</button>
                    <p v-if="submitted">Thank you for your feedback!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-rec" v-if="PRODUCT_CARD.recomend_products">
        <p class="px28-500 product-rec__title" v-if="PRODUCT_CARD?.recomend_products.length > 0">{{ $t("rec1") }}:</p>
        <swiper
          :slidesPerView="4"
          :spaceBetween="20"
          :modules="modules"
          class="mySwiper"
          :breakpoints="breakpoints"
        >
          <swiper-slide
            v-for="(item, index) in PRODUCT_CARD.recomend_products"
            :key="index"
            ><Card :PRODUCT_CARD="item"
          /></swiper-slide>
        </swiper>
      </div>
    </div>
    <Modal :open="isOpen1" @close="isOpen1 = !isOpen1">
      <div class="call" v-if="PRODUCT_CARD.product">
        <p class="call-title px28-500" style="margin-bottom: 10px">
          {{ $t("buyClick") }}
        </p>
        <p class="call-text px18 fw400">
          {{ $t("nameProduct") }}:
          <span class="fw600" v-html="PRODUCT_CARD.product.title"></span>
        </p>
        <p class="call-text px18 fw400">
          {{ $t("art") }}: <span class="fw600" v-html="PRODUCT_CARD.product.artikul"></span>
        </p>
        <div class="call-inputs">
          <p class="px16">{{ $t("num") }}</p>
          <input v-model="phone" v-mask="'+7##########'" placeholder="+7" type="text" class="input" />
        </div>
        <div class="call-btn">
          <button class="button-active" @click="sendBuy">{{ $t("buy") }}</button>
        </div>
        <div class="call-check">
          <input checked type="checkbox" name="" id="check3" />
          <label for="check3"
            >{{ $t("agree") }}</label
          >
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide,  } from "swiper/vue";
import "swiper/css/navigation";
import "swiper/css";
import { Navigation } from "swiper";
import Card from "@/components/main/Card.vue";
import Modal from "@/components/modal/Modal.vue";
// import ReviewModal from "@/components/modal/ReviewModal.vue";
import { ref } from "vue";
// import StarRating from "vue-star-rating";
import axios from "axios";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
// import ThumbsSlider from "@/components/ThumbsSlider.vue";
import navBar from "@/components/ui/navBar.vue";
import { useToast } from "vue-toastification";
import 'viewerjs/dist/viewer.css';
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Review from "@/components/main/Review.vue";
import Reviews from "@/components/account/Reviews.vue";

const toast = useToast();
export default {
  components: {
    Reviews,
    Review,
    Swiper,
    SwiperSlide,
    Card,
    Modal,
    navBar,
    BreadCrumb
  },
  data() {
    return {
      selectedQuantity: null,
      color_id: null,
      size_id: null,
      productName: "",
      swiper: null,
      piece: 1,
      disableMinus: false,
      disablePlus: false,
      rating: null,
      resetableRating: 2,
      currentRating: "No Rating",
      mouseOverRating: null,
      name: null,
      product_name: "",
      phone: null,
      product_id: null,
      review: "",
      isActive: false,
      active_answer: [],
      active_title: [],
      visibleFaq: "true",
      thumbActive: 0,
      hideFaq: "false",
      showSize: false,
      isVisible: false,
      link: [],
      main_imageP: null,
      imagesP: false,
      breakpoints: {
        970: {
          spaceBetween: 20,
          slidesPerView: 4,
        },
        0: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      answer: [
        {
          id: 1,
          title: this.$t("description"),
          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.",
        },
        {
          id: 2,
          title: this.$t("char"),
          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.",
        },
        {
          id: 3,
          title: this.$t("delivery"),
          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.",
        },
        {
          id: 4,
          title: this.$t("rev"),
          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.",
        },
      ],
      favoriteActive: false,
      activeCart: false,
      count: 1,
      category: [
        {
          link: "/",
          title: this.$t("main")
        }, 
        {
          link: "/catalog/palto-vesna-osen",
          title: this.$t("catalog")
        },
      ],
    };
  },
  setup() {
    const isOpen = ref(false);
    const isOpen1 = ref(false);
    return {
      isOpen,
      isOpen1,
      modules: [Navigation],
    };
  },
  created() {
    this.GET_PRODUCT_CARD({ slug: this.$route.params.slug });
    this.getDoc();
    this.CHECK_FAVOURITE_LIST();
    if (!localStorage.getItem("cart_products")) {
      localStorage.setItem("cart_products", "[]");
    }
    if (!localStorage.getItem("product_count")) {
      localStorage.setItem("product_count", "[]");
    }
  },
  mounted() {
    this.inTheCart();

    this.activeFavorite;
    let localStorageProducts = JSON.parse(
      localStorage.getItem("cart_products")
    );
  },
  methods: {
    ...mapActions([
      "GET_PRODUCT_CARD",
      "LOCALSTORAGE_PRODUCT",
      "CHECK_FAVOURITE_LIST",
      "DELETE_PRODUCT",
    ]),
    ...mapMutations(["ADD_FAVORITES"]),
    onSwiper(swiper) {
      this.swiper = swiper;
      // console.log("Swiper instance in parent:", swiper);
    },
    getQuantity() {
      // Поиск объекта в массиве по выбранному цвету и размеру
      const selectedProduct = this.PRODUCT_CARD.quantity.find(product => 
        Number(product.color_id) === Number(this.color_id) && Number(product.size_id) === Number(this.size_id)
      );
      // Если объект найден, получаем quantity
      if (selectedProduct) {
        this.selectedQuantity = selectedProduct.quantity;
      } else {
        this.selectedQuantity = null;
      }
      // console.log('selectedQuantity', this.selectedQuantity);
    },
    onHandleChange(event) {
      this.size_id = event.target.value
      console.log(this.size_id);
    },
    saveCounterToLocalStorage() {
      const itemCount = JSON.parse(localStorage.getItem("product_count")) || [];
      const foundItemIndex = itemCount.findIndex(item => item.id === this.PRODUCT_CARD.product.id);
      if (foundItemIndex !== -1) {
        // Обновление количества товаров в массиве localStorage
        itemCount[foundItemIndex].count = this.count;
      } else {
        // Добавление нового элемента, если его нет в массиве
        itemCount.push({ id: this.PRODUCT_CARD.product.id, count: this.count });
      }

      // Сохранение обновленного массива в localStorage
      localStorage.setItem("product_count", JSON.stringify(itemCount));
    },
    sendBuy() {
      this.form = {
        product_id: this.PRODUCT_CARD.product.id,
        phone: this.phone,
        name: this.product_name,
      };
      axios
        .post("click", this.form)
        .then((response) => {
          if (response.status == 200) {
            toast.success(this.$t('sendSuccess'));
            this.isOpen1 = false;
            // this.product_id = "";
            this.phone = "";
          } else {
            toast.error(this.$t('allErr'));
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(this.$t('allErr'));
        });
    },
    minusValue(value) {
      if (this.count > 1) {
        this.count--;
        this.$store.commit("SET_CART_COUNT", {
          id: value.id,
          count: this.count,
        });
        this.saveCounterToLocalStorage();
      } else if (this.count === 1) {
        this.activeCart = !this.activeCart;
        this.$store.commit("DELETE_PRODUCT", { id: value.id });
        this.count = 1;
        this.$emit("DELETE_FROM_BASKET");
        this.saveCounterToLocalStorage();
      }
    },
    deleteFromBasket(value) {
      this.$store.commit("DELETE_PRODUCT", { id: value.id });
      this.$emit("DELETE_FROM_BASKET");
    },
    plusValue(value) {
      this.count++;
      this.$store.commit("SET_CART_COUNT", { id: value.id, count: this.count });
      this.saveCounterToLocalStorage();
    },
    getDoc() {
      axios.get("get-review-doc").then((res) => {
        this.link = res.data;
      });
    },
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    showCurrentRating(rating) {
      this.currentSelectedRating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    activeColor(item, index) {
      if(this.isActive == false) {
        // console.log(item);
        this.isActive = item.id;
      } else{
        this.isActive = index;
      }
      // swiper.slideTo(index);
      this.swiper.slideTo(index);
      this.thumbActive = index
      this.color_id = item.id;
    },
    addProductToBasket() {
      this.ADD_PRODUCT_TO_BASKET(this.productInfo);
    },
    addFavorite(value) {
      this.favoriteActive = !this.favoriteActive;
      this.ADD_FAVORITES(value);
    },
    addToCart(product, count) {
      if(this.color_id && this.size_id) {
        this.getQuantity();
        console.log(this.count);
        if(this.selectedQuantity >= this.count) {
          this.activeCart = !this.activeCart;
          count = this.count;
          product.color_id = this.color_id;
          product.size_id = this.size_id;
          this.$store.commit("ADD_TO_CART", { product, count });
          this.saveCounterToLocalStorage();
        } else {
          toast.error(`${this.$t('storage1')} ${this.selectedQuantity} ${this.$t('storage2')}`)
        }
      } else {
        toast.error(this.$t('chooseColor'))
      }
    },
    removeValue(value) {
      this.$store.commit("DELETE_PRODUCT", { id: value.id });
      this.count = 1;
      this.$emit("DELETE_FROM_BASKET");
    },
    inTheCart() {
      if (this.cartList && this.PRODUCT_CARD?.product) {
        let findCart = this.cartList.find(
          (i) => i.id === this.PRODUCT_CARD?.product.id
        );
        if (findCart) {
          this.activeCart = true;
          this.count = 1;
        } else {
          this.activeCart = false;
        }
      }
    },
    inTheFavourite() {
      // console.log("favoriteList", this.favoriteList);
      if (this.favoriteList && this.PRODUCT_CARD?.product) {
        let findCart = this.favoriteList.find(
          (i) => i.id === this.PRODUCT_CARD?.product?.id
        );
        if (findCart) {
          this.favoriteActive = true;
        } else {
          this.favoriteActive = false;
        }
      }
    },
  },
  computed: {
    ...mapState(["cdn"]),
    ...mapGetters({
      PRODUCT_CARD: "PRODUCT_CARD",
      cartList: "GET_CART_LIST",
      cdn: "GET_CDN",
      basket: "GET_BASKET_OPENED",
      favoriteList: "GET_FAVOURITE_LIST",
    }),
    currentRatingText() {
      return this.rating
        ? "You have selected " + this.rating + " stars"
        : "No rating selected";
    },
    mainImg() {
      return this.PRODUCT_CARD.product.main_image || false;
    },
    imagesPP() {
      return this.PRODUCT_CARD.product.product_images || false;
    },
    mouseOverRatingText() {
      return this.mouseOverRating
        ? "Click to select " + this.mouseOverRating + " stars"
        : "No Rating";
    },
    activeFavorite() {
      if (this.favoriteList) {
        this.favoriteList.filter((i) => {
          if (this.PRODUCT_CARD?.product) {
            if (i.id === this.PRODUCT_CARD?.product.id) {
              this.favoriteActive = true;
            }
          }
        });
      }
    },
  },
  watch: {
    $route() {
      this.GET_PRODUCT_CARD({ slug: this.$route.params.slug });
      (this.main_imageP = this.PRODUCT_CARD.product.main_image),
      (this.imagesP = this.PRODUCT_CARD.product.product_images);
    },
    showSize: function () {
      if (this.showSize) {
        // document.documentElement.style.overflow = "hidden";
        return;
      }
      // document.documentElement.style.overflow = "auto";
    },
    PRODUCT_CARD() {
      this.inTheCart();
      this.inTheFavourite();
    },
    cartList() {
      this.inTheCart();
    },
    favoriteList() {
      this.inTheFavourite();
    },
    count() {
      this.saveCounterToLocalStorage();
    }
    // product() {
    //   (this.main_imageP = this.PRODUCT_CARD.product.main_image),
    //   (this.imagesP = this.PRODUCT_CARD.product.product_images);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/product.scss";

.feedback-form {
  max-width: 400px;
  margin: auto;
  padding: 1rem;
  border: 1px solid #ccc;
}

.feedback-form h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.feedback-form textarea {
  width: 95%;
  height: 100px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.feedback-form button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #282828;
  color: white;
  border: none;
  cursor: pointer;
}

.feedback-form button:hover {
  background-color: #218838;
}

.feedback-form p {
  margin-top: 1rem;
  text-align: center;
  color: #28a745;
}
</style>
