<template>
    <div class="tab-items m-none">
      <ul class="tab-list">
        <li v-for="tab in tabItems" :key="tab" class="tab-item" :class="{ activeItem: tab === selected }">
          <div
            :class="{ active: tab === selected }"
            class="tab-link tab-title"
            @click="setTab(tab)"
          >
            {{ tab }}
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      tabItems: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
        required: true,
      },
    },
    methods: {
      setTab(tab) {
        this.$emit("setTab", tab);
      },
    },
  };
  </script>

<style lang="scss" scoped>
.active {
    border-bottom: 2px solid #000000;
    color: #000000;
}
.activeItem {
  background: #FFFFFF !important;
}
.tab {
    &-list {
        display: flex;
    }
    &-item {
        background: rgba(255, 255, 255, 0.7);
        border-bottom: 1px solid #efefef;
        text-transform: uppercase;
        line-height: 40px;
        color: #555555;
        font-weight: 500;
        &:not(:last-child) {
            padding-right: 20px;
        }
    }
    &-link {
        cursor: pointer;
    }
}
</style>