import { createStore } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
import i18n from "@/i18n.js";
const toast = useToast();
export default createStore({
  state: {
    cdn: "https://cdn.terisarayi.kz/",
    about: {
      info: [],
    },
    info: {},
    main: [],
    order_product: localStorage.getItem("product") || {},
    // product: [],
    brand: [],
    page: [],
    header: [],
    category: [],
    product: {
      card: [],
    },
    user: {},
    isAuth: false,
    unAuth: false,
    userI: [],
    favoritesArray: JSON.parse(localStorage.getItem("favorite")) || [],
    cartLength: 0,
    favouriteLength: 0,
    cartList: JSON.parse(localStorage.getItem("cart_products")) || [],
    productById: [],
    searchData: null,
    del: [],
  },
  getters: {
    STATE(state) {
      return state;
    },
    GET_CDN: (state) => state.cdn,
    MAIN_PAGE: (state) => state.main,
    GET_ORDER_PRODUCT: (state) => state.order_product,
    PRODUCT_CARD: (state) => state.product.card,
    BRAND_PAGE: (state) => state.brand,
    PAGE: (state) => state.page,
    HEADER: (state) => state.header,
    PRODUCT_BY_CATEGORY: (state) => state.category,
    getUnAuth: (state) => state.unAuth,
    getIsAuth: (state) => state.isAuth,
    getUser: (state) => state.setUser,
    GET_FAVOURITE_COUNT: (state) => state.favoritesArray,
    GET_CART_LENGTH: (state) => state.cartLength,
    GET_FAVOURITE_LENGTH: (state) => state.favouriteLength,
    GET_CART_LIST: (state) => state.cartList,
    GET_FAVOURITE_LIST: (state) => state.favoritesArray,
    GET_PRODUCTS_BY_ID: (state) => state.productById,
    SEARCH_RESULT: (state) => state.searchData, 
    DELIVERY: (state) => state.del,
  },
  mutations: {
    SET_ABOUT_INFO(state, data) {
      state.about.info = data;
    },
    SET_SEARCH(state, result) {
        state.searchData = result
    },
    SET_MAIN_PAGE(state, data) {
      state.main = data;
    },
    SET_PRODUCT_CARD(state, data) {
      state.product.card = data;
    },
    SET_LOCALSTORAGE_PRODUCT(state, data) {
      state.order_product = data;
    },
    SET_BRAND_PAGE(state, data) {
      state.brand = data;
    },
    SET_DELIVERY(state, data) {
      state.del = data;
    },
    SET_PAGE(state, data) {
      state.page = data;
    },
    SET_HEADER(state, data) {
      state.header = data;
    },
    SET_PRODUCT_BY_CATEGORY(state, data) {
      state.category = data;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
    },
    SET_UN_AUTH(state, resp) {
      state.unAuth = resp;
    },
    setUser(state, userI) {
      state.setUser = userI;
    },
    SET_INFO(state, info) {
      state.info = info;
    },
    SET_CART_LENGTH(state, length) {
      state.cartLength = length;
    },
    SET_FAVOURITE_LENGTH(state, length) {
      state.favouriteLength = length;
    },
    SET_CART_LIST(state, cartList) {
      state.cartList = cartList;
    },
    SET_FAVOURITE_LIST(state, favoritesArray) {
      state.fa = favoritesArray;
    },
    SET_PRODUCT_BY_ID(state, product) {
        state.productById = product;
      },
    SET_CART_COUNT(state, { id, count }) {
      state.cartList.forEach((item, idx) => {
        if (item.id == id) {
          state.cartList[idx].count = count;
        }
      });
      localStorage.setItem("cart_products", JSON.stringify(state.cartList));
    },
    ADD_FAVORITES(state, product) {
      // console.log("product", product);
      let favoriteStorage = JSON.parse(localStorage.getItem("favorite"));
      localStorage.setItem("favorite", JSON.stringify([product]));
      if (favoriteStorage == null) {
        localStorage.setItem("favorite", JSON.stringify([product]));
        state.favouriteLength = localStorage.getItem("favorite").length;
        state.favoritesArray = JSON.parse(localStorage.getItem("favorite"));
        toast.success(i18n.global.t(`addFav`));
      } else if (favoriteStorage == []) {
        favoriteStorage.push(product);
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        state.favouriteLength = favoriteStorage.length;
        state.favoritesArray = favoriteStorage;
        toast.success(i18n.global.t(`addFav`));
      } else if (favoriteStorage.find((item) => item.id == product.id)) {
        favoriteStorage.forEach((item, index) => {
          if (item.id == product.id) {
            favoriteStorage.splice(index, 1);
          }
        });
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        state.favouriteLength = favoriteStorage.length;
        state.favoritesArray = favoriteStorage;
        toast.error(i18n.global.t(`delFav`));
      } else {
        favoriteStorage.push(product);
        state.favouriteLength = favoriteStorage.length;
        state.favoritesArray = favoriteStorage;
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        toast.success(i18n.global.t(`addFav`));
      }
    },
    ADD_TO_CART(state, { product }) {
      const productCount = JSON.parse(localStorage.getItem("product_count")) || [];
      const productIndex = productCount.findIndex(item => item.id === product.id);
      const countValue = productIndex !== -1 ? productCount[productIndex].count : 1;
      product["count"] = countValue;
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      localStorage.setItem("cart_products", JSON.stringify([product]));
      if (cartList == null) {
        localStorage.setItem("cart_products", JSON.stringify([product]));
        state.cartLength = localStorage.getItem("cart_products").length;
        state.cartList = cartList;
        toast.success(i18n.global.t(`added`));
      } else if (cartList == []) {
        cartList.push(product);
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = cartList.length;
        state.cartList = cartList;
        toast.success(i18n.global.t(`added`));
      } else if (cartList.find((item) => item.id == product.id)) {
        cartList.forEach((item, index) => {
          if (item.id == product.id) {
            cartList.splice(index, 1);
          }
        });
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = cartList.length;
        state.cartList = cartList;
        toast.success(i18n.global.t(`added`));
      } else {
        cartList.push(product);
        state.cartLength = cartList.length;
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartList = cartList;
        toast.success(i18n.global.t(`added`));
      }
    },
    ADD_TO_CART_PRODUCT(state, { product, countValue }) {
      product["count"] = countValue ? countValue : localStorage.getItem("product_count").count;
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      if (cartList && cartList.find((item) => item.id == product.id)) {
        cartList.filter((i) => {
          if (i.id === product.id) {
            i.count = countValue;
            localStorage.setItem("cart_products", JSON.stringify(cartList));
          }
        });
        return false;
      } else if (cartList !== null) {
        cartList.push(product);
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = localStorage.getItem("cart_products");
      } else {
        localStorage.setItem("cart_products", JSON.stringify([product]));
        state.cartLength = localStorage.getItem("cart_products");
      }
    },
    DELETE_PRODUCT(state, { id }) {
      let cartList = state.cartList.filter((t) => t.id !== id);
      localStorage.setItem("cart_products", JSON.stringify(cartList));
      state.cartList = cartList;
      toast.error(i18n.global.t(`deleted`));
      state.cartLength = cartList.length
      this.dispatch("CHECK_CARD_LIST");
    },
    DELETE_FAVORITES(state, { id }) {
      console.log('asdhaksjdhaskdhkajshdkjashdID-======',id);
      let favoritesArray = state.favoritesArray.filter((t) => t.id !== id);
      localStorage.setItem("favorite", JSON.stringify(favoritesArray));
      state.favoritesArray = favoritesArray;
      toast.error(i18n.global.t(`delFav`));
      state.favouriteLength = favoritesArray.length
      this.dispatch("CHECK_FAVOURITE_LIST");
    },
  },
  actions: {
    //Главная страница
    async GET_MAIN_PAGE({ commit }) {
      await axios
        .get("https://admin.terisarayi.kz/api/home-page", {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
        .then((response) => commit("SET_MAIN_PAGE", response.data))
        .catch((error) => {
          console.log(error);
        });
    },
    // страница доставка и оплата
    async GET_DELIVERY({commit}) {
      await axios
      .get("https://admin.terisarayi.kz/api/get-delivery", {
        params: {
          lang: localStorage.getItem("lang"),
        },
      })
      .then((response) => commit("SET_DELIVERY", response.data.pages))
      .catch((error) => {
        console.log(error);
      });
    },

    //Страницы ФУТЕР
    async GET_PAGE({ commit }) {
      // console.log(slug);
      await axios
        .get(`https://admin.terisarayi.kz/api/get-pages?slug=blog`, {
          params: {
            lang: localStorage.getItem("lang"),
            // slug: slug,
          },
        })
        .then((response) => commit("SET_PAGE", response.data))
        .catch((error) => {
          console.log(error);
        });
    },

    //Получение товара
    LOCALSTORAGE_PRODUCT({ commit }, PRODUCT_CARD) {
      // console.log("asdasd");
      localStorage.setItem("product", JSON.stringify(PRODUCT_CARD));
      commit("SET_LOCALSTORAGE_PRODUCT", PRODUCT_CARD);
    },

    async GET_PRODUCT_CARD(context, slug) {
      await axios
        .get("product", {
          params: {
            lang: localStorage.getItem("lang"),
            slug: slug,
          },
        })
        .then((response) => context.commit("SET_PRODUCT_CARD", response.data));
    },
    // страница О нас
    async GET_ABOUT_INFO(context) {
      await axios
        .get("pages/:slug", {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
        .then((response) => context.commit("SET_ABOUT_INFO", response.data));
    },

    //страницы брендов
    async GET_BRAND_PAGE({ commit }, data) {
      await axios
        .get(
          `get-brand-products?filter_id[] = ${
            data.filter_id.length ? data.filter_id : "[]"
          }`,
          {
            params: {
              lang: localStorage.getItem("lang"),
              brand_id: data.brand_id,
            },
          }
        )
        .then((response) => commit("SET_BRAND_PAGE", response.data))
        .catch((error) => {
          console.log(error);
        });
    },
    // Получение хедера
    async GET_HEADER({ commit }) {
      await axios
        .get("get-header", {
          params: {
            lang: localStorage.getItem("lang"),
          },
        })
        .then((response) => commit("SET_HEADER", response.data))
        .catch((error) => {
          console.log(error);
        });
    },
    //search
    async GET_SEARCH_RESULT({commit}, {text}) {
        let result = await axios
        .get('https://admin.terisarayi.kz/api/' + 'search', {
            params: {
              lang: localStorage.getItem('lang'),
              text: text
            }
        })
        console.log("searchData",result)
        commit('SET_SEARCH', result.data)
    },

    //Получение товаров по категориям
    async GET_PRODUCT_BY_CATEGORY({ commit }, data) {
      await axios
        .get(
          `get-products?filter_id[] = ${
            data.filter_id.length ? data.filter_id : "[]"
          }`,
          {
            params: {
              lang: localStorage.getItem("lang"),
              slug: data.slug,
              order_by: data.order_by,
              page: data.page
            },
          }
        )
        .then((response) => commit("SET_PRODUCT_BY_CATEGORY", response.data))
        .catch((error) => {
          console.log(error);
        });
    },

    async FETCH_PRODUCT_BY_ID({ commit }, { id }) {
        // console.log("id",id);
        await axios
          .post(
            `products-by-id`,{
            'ids': id
            },
            {
              params: {
                lang: localStorage.getItem("lang"),
              },
            }
          )
          .then((response) => commit("SET_PRODUCT_BY_ID", response.data))
          .catch((error) => {
            console.log(error);
          });
      },
    checkAuth({ commit, state }) {
      if (localStorage.getItem("access_token")) {
        commit("SET_AUTH", true);
      }
    },
    async FETCH_USER({ commit }) {
      try {
          const response = await axios.post(`auth/me`,{}, {
          headers: {
              Authorization: `bearer ${localStorage.getItem("access_token")}`,
          },
          });
          commit("setUser", response.data);
          commit("SET_AUTH", true);
          commit("SET_UN_AUTH", false);
      } catch (error) {
          console.log(error);
          localStorage.removeItem("access_token");
          commit("SET_UN_AUTH", true);
          commit("SET_AUTH", false);
      }
    },
    logout({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_UN_AUTH", true);
      commit("SET_AUTH", false);
    },
    CART_LENGTH_ACTION({ commit }) {
      let data = localStorage.getItem("cart_products")
      let stringData = JSON.parse(data)
      commit("SET_CART_LENGTH", stringData?.length);
    },
    CART_FAVOURIT_LENGTH_ACTION({ commit }) {
      let data = localStorage.getItem("favorite")
      let stringData = JSON.parse(data)
      commit("SET_FAVOURITE_LENGTH", stringData?.length);
    },
    CHECK_CARD_LIST({ commit }) {
      commit(
        "SET_CART_LIST",
        JSON.parse(localStorage.getItem("cart_products"))
      );
    },
    CHECK_FAVOURITE_LIST({ commit }) {
      commit(
        "SET_FAVOURITE_LIST",
        JSON.parse(localStorage.getItem("favorite"))
      );
    },
  },
});
