<template>
  <div>
    <p class="goods-card__num">{{areaNumber}}</p>
    <div class="goods-card__box">
      <img :src="$store.state.cdn + productCard.main_image" alt="" />
      <div class="goods-card__right">
        <div class="text">
          <p class="px16 fw500" v-html="productCard.title"></p>
          <div v-if="typeComponent !== 'favourite'" class="counter">
            <p class="px16 fw500">{{ $t("pieces") }}: <span class="fw600">{{ productCard.count ? productCard.count : count }}</span></p>
            <!-- <button @click="minusValue(productCard)">-</button> -->
            <!-- <button style="color: #000" class="num px20 fw500">
              {{ productCard.count ? productCard.count : count }}
            </button> -->
            <!-- <button @click="plusValue(productCard)">+</button> -->
          </div>
          <div v-if="typeComponent !== 'favourite'" class="delete px14 fw700 gray" style="cursor: pointer"  @click.prevent="removeValue(productCard)">{{ $t("delete") }}</div>
          <div v-if="typeComponent == 'favourite'" class="delete px14 fw700 gray" style="cursor: pointer"  @click.prevent="removeFav(productCard)">{{ $t("delete") }}</div>
        </div>
        <p class="price">
          {{ formatPrice(productCard.current_price) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  props:['productCard','areaNumber','typeComponent'],
  data: () => ({
    favoriteActive: false,
    count: 1,
  }),
  created() {
    this.CHECK_FAVOURITE_LIST();
    if (!localStorage.getItem("cart_products")) {
      localStorage.setItem("cart_products", "[]");
    }
  },
  mounted() {

    this.activeFavorite;
    let localStorageProducts = JSON.parse(
      localStorage.getItem("cart_products")
    );
  },
  computed: {
    ...mapGetters({
      cartList: "GET_CART_LIST",
      cdn: "GET_CDN",
      basket: "GET_BASKET_OPENED",
      favouriteList: "GET_FAVOURITE_LIST",
    }),
    basketData() {
      let newCards = {
        ids: [],
        counts: [],
        test: ''
      }
      this.cartList.map(item => {
        newCards.ids.push(item.id)
        newCards.counts.push(item.count)
      })
      return newCards;

    },
    favourtiteData() {
      let newCards = {
        ids: [],
        counts: [],
        test: "",
      };
      this.favouriteList.map((item) => {
        newCards.ids.push(item.id);
        newCards.counts.push(item.count);
      });
      return newCards;
    },
  },
  methods: {
    ...mapActions(["DELETE_PRODUCT","CHECK_FAVOURITE_LIST","FETCH_PRODUCT_BY_ID", "DELETE_FAVORITES"]),
    ...mapMutations(["ADD_FAVORITES","DELETE_FAVORITES"]),
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    minusValue(value) {
      if (value.count > 1) {
        value.count--;
        this.$store.commit("SET_CART_COUNT", {
          id: value.id,
          count: value.count,
        });
      } else if (value.count === 1) {
        this.activeCart = !this.activeCart;
        this.$store.commit("DELETE_PRODUCT", { id: value.id });
        value.count = 1;
        this.$emit("DELETE_FROM_BASKET");
      }
    },
    plusValue(value) {
      value.count++;
      this.$store.commit("SET_CART_COUNT", { id: value.id, count: value.count });
    },
    removeValue(value) {
      this.$store.commit("DELETE_PRODUCT", { id: value.id });
      this.count = 1;
      this.$emit("DELETE_FROM_BASKET");
      this.FETCH_PRODUCT_BY_ID({id: this.basketData.ids})
    },
    removeFav(value) {
      this.$store.commit("DELETE_FAVORITES", { id: value.id });
      this.count = 1;
      this.$emit("DELETE_FROM_FAVOURITE");
      this.CHECK_FAVOURITE_LIST()
      this.FETCH_PRODUCT_BY_ID({id: this.favourtiteData.ids})
    },

  },
  watch: {
    productCard() {

    },
    cartList() {

    },
    favoriteList() {

    },
  }
};
</script>

<style lang="scss" scoped>
/* @import "@/assets/styles/header.scss"; */
.goods {
  &-card {
    &__right {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }
    &__num {
      font-size: 10px;
      font-weight: 300;
      margin-bottom: 10px;
    }
    &__box {
      padding-left: 10px;
      display: flex;
      align-items: end;
      margin-bottom: 23px;
      img {
        width: 80px;
        height: 108px;
        margin-right: 18px;
      }
      .counter {
        margin: 5px 0;
        // border: 1px solid #CFCFCF;
        width: 100px;
        .num {
            color: rgb(0, 0, 0);
        }
        button {
            color: #77757F;
            border: 1px solid #CFCFCF;
            width: 32px;
            height: 30px;
            background-color: #fff;
            font-size: 18px;
        }
      }
    }
    .price {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
@media (max-width: 970px) {
  .goods {
    &-card {
      &__right {
        position: relative;
        .price {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      &__box {
        img {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
