<template>
  <div
    class="navs-wrap"
    :class="{ 'main-color': isHome, 'other-color': !isHome }"
  >
    <router-link class="navs-main" to="/" v-if="!isHome">
      <img src="@/assets/images/logo.jpg" alt="" />
    </router-link>
    <div class="navs">
      <router-link class="px16-400 black" to="/catalog/palto-vesna-osen"
        >{{ $t("catalog") }}</router-link
      >
      <router-link class="px16-400 black" to="/blog">Блог</router-link>
      <router-link class="px16-400 black" to="/delivery"
        >{{ $t("delivery") }}</router-link
      >
      <router-link class="px16-400 black" to="/contacts">{{ $t("contacts") }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHome: false,
    };
  },
  created() {
    if (this.$route.path === "/") {
      this.isHome = true;
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";
.main-color {
  background: #f6f5f5;
}
.other-color {
  background-color: #fff;
}
.router-link-active {
  padding: 6px 25px;
  border-radius: 30px;
  background-color: #fff;
}
.navs {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 477px;
  border-radius: 30px;
  background: #c0c0c0;
  padding: 9px 8px;
  a {
    line-height: 20px;
    margin: 0 7px;
  }
  &-wrap {
    padding: 21px 0;
    // margin-top: -5px;
    display: flex;
    align-items: center;
  }
  &-main {
    img {
      width: 53px;
      height: 96px;
    }
  }
}
@media (max-width: 970px) {
  .navs {
    &-wrap {
      display: none;
    }
  }
}
</style>
