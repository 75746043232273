import { createWebHistory, createRouter } from 'vue-router';
import MainPage from '@/pages/MainPage.vue';
import Contacts from "@/pages/Contacts.vue";
import Blog from "@/pages/Blog.vue";
import Delivery from "@/pages/Delivery.vue";
import Catalog from "@/pages/Catalog.vue"
import Pages from "@/pages/Pages.vue"
import Error from "@/pages/Error.vue"
import Account from "@/pages/Account.vue"
import Basket from "@/pages/Basket.vue"
import Product from "@/pages/Product.vue"
import Search from "@/pages/Search.vue"
import Articles from "@/pages/Articles.vue"
import Reset from "@/pages/Reset.vue"
import Favorites from "@/pages/Favorites.vue"

const routes = [
    {
        path: '/',
        name: 'main-page',
        component: MainPage
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/delivery',
        name: 'Delivery',
        component: Delivery
    },
    {
        path: '/catalog/:slug',
        name: 'catalog-page',
        component: Catalog
    },
    {
        // path: '/pages',
        path: '/pages/:slug',
        name: 'pages',
        component: Pages
    },
    { 
        path: "/404", 
        name: "404", 
        component: Error,
    }, 
    { 
        path: "/:catchAll(.*)", 
        redirect: "/404" 
    },
    {
        path: "/account",
        name: "account",
        component: Account,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/basket",
        name: "basket",
        component: Basket,
        meta: {
            requiresAuth: true
          }
    },
    {
        path: "/product/:slug",
        name: "product",
        component: Product
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/article/:slug',
        name: 'article',
        component: Articles
    },
    {
        path: '/reset',
        name: 'reset',
        component: Reset
    },
    {
        path: '/favorites',
        name: Favorites,
        component: Favorites
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

router.beforeEach((to, from, next) => {
    document.title = `TS`;
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('access_token')) {
        // console.log('asasxs');
        next()
      } else {
        console.log('dddd');      
        next({ name: 'main-page' })
        return
      }
    } else {
      next()
    }
  });

export default router