<template>
    <div class="container">
      <div class="pages" v-if="art">
        <img class="pages-img" :src="cdn + art.image" alt="" />
        <div class="pages-text">
          <p  class="pages-text__title px28-500" v-html="art.title"></p>
          <p class="px16-400" v-html="art.description"></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  import { mapState } from "vuex";
  export default {
    data() {
        return {
            art: []
        }
    },
    created() {
        this.getArticle();
    },
    methods: {
        getArticle() {
            axios.get(`articles/article?lang=${localStorage.getItem("lang")}&slug=${this.$route.params.slug}`)
            .then( res => {
                this.art = res.data.data
            })
        }
    },
    computed: {
        ...mapState(['cdn'])
    },
    watch: {
      $route() {
        // this.GET_PAGE(this.$route.params.slug);
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .pages {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-img {
      width: 40%;
      height: 40vw;
      max-height: 800px;
      margin-bottom: 20px;
      object-fit: cover;
    }
    &-text {
      width: 58%;
      &__title {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 960px) {
    .pages {
      flex-direction: column;
      &-img {
        width: 100%;
        height: 100vw;
      }
      &-text {
        width: 100%;
      }
    }
  }
  </style>
  