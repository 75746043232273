<template>
    <div class="container">
        <div class="rev" v-if="MAIN_PAGE?.clientComments">
            <h5 class="rev-title section-title">{{ $t("client") }}</h5>
            <div class="rev-cards">
                <swiper
                    :slidesPerView="4"
                    :spaceBetween="30"
                    :modules="modules"
                    :navigation="navigation"
                    class="mySwiper"
                    :breakpoints="breakpoints"
                >
                    <swiper-slide v-for="(item, index) in MAIN_PAGE?.clientComments" :key="index">
                        <div class="rev-cards__item">
                            <img loading="lazy" :src="cdn + item.image" alt="">
                            <div class="rev-cards__item-box">
                                <p class="nick px24 fw400" v-html="item.login"></p>
                                <img class="star" src="@/assets/images/main/stars.png" alt="">
                                <p class="name fw500" v-html="item.name"></p>
                                <p class="text px14 fw400" v-html="item.comment"></p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="nav">
                    <div class="swiper-button-prev">
                        <img src="@/assets/images/main/prev.png" alt="" />
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/images/main/next.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { mapState } from 'vuex';
export default {
    components: {Swiper, SwiperSlide},
    props: ["MAIN_PAGE"],
    data() {
        return {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                970: {
                    spaceBetween: 24,
                    slidesPerView: 3,
                },
                0: {
                    spaceBetween: 10,
                    slidesPerView: 1,
                },
            },
            card: [
                {
                    nick: '@isenova',
                    name: 'Алия Исенова',
                    text: 'Шубы, дубленки - это необходимо. Мы с сестренками второй год как выбираем верхнюю одежду из овчины. Сегодня купили сестренке шубку в @terisarayi. Выбор самый огромный в городе.',
                    image: require('@/assets/images/main/rev1.png')
                },
                {
                    nick: '@almira_life_',
                    name: 'Альмира',
                    text: 'Огромный выбор верхней одежды для погоды Астаны. Можно подобрать модель на любой стиль и размер',
                    image: require('@/assets/images/main/rev2.png')
                },
                {
                    nick: '@dinarasatzhan',
                    name: 'Динара Сатжан',
                    text: 'Если вы в поисках подарка себе любимой, подруге, сестре, маме, мужу - загляните в Терi Сарайы. Это один из первых магазинов в Астане, специализируется только на верхней одежде.',
                    image: require('@/assets/images/main/rev3.png')
                },
                {
                    nick: '@isenova',
                    name: 'Алия Исенова',
                    text: 'Шубы, дубленки - это необходимо. Мы с сестренками второй год как выбираем верхнюю одежду из овчины. Сегодня купили сестренке шубку в @terisarayi. Выбор самый огромный в городе.',
                    image: require('@/assets/images/main/rev1.png')
                },
                {
                    nick: '@almira_life_',
                    name: 'Альмира',
                    text: 'Огромный выбор верхней одежды для погоды Астаны. Можно подобрать модель на любой стиль и размер',
                    image: require('@/assets/images/main/rev2.png')
                },
                {
                    nick: '@dinarasatzhan',
                    name: 'Динара Сатжан',
                    text: 'Если вы в поисках подарка себе любимой, подруге, сестре, маме, мужу - загляните в Терi Сарайы. Это один из первых магазинов в Астане, специализируется только на верхней одежде.',
                    image: require('@/assets/images/main/rev3.png')
                },
                {
                    nick: '@isenova',
                    name: 'Алия Исенова',
                    text: 'Шубы, дубленки - это необходимо. Мы с сестренками второй год как выбираем верхнюю одежду из овчины. Сегодня купили сестренке шубку в @terisarayi. Выбор самый огромный в городе.',
                    image: require('@/assets/images/main/rev1.png')
                },
                {
                    nick: '@almira_life_',
                    name: 'Альмира',
                    text: 'Огромный выбор верхней одежды для погоды Астаны. Можно подобрать модель на любой стиль и размер',
                    image: require('@/assets/images/main/rev2.png')
                },
                {
                    nick: '@dinarasatzhan',
                    name: 'Динара Сатжан',
                    text: 'Если вы в поисках подарка себе любимой, подруге, сестре, маме, мужу - загляните в Терi Сарайы. Это один из первых магазинов в Астане, специализируется только на верхней одежде.',
                    image: require('@/assets/images/main/rev3.png')
                },
            ]
        }
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },
    computed: {
        ...mapState(["cdn"])
    }
}
</script>

<style lang="scss" scoped>
.swiper {
    &-button {
        &-next, &-prev {
            &::after {
                opacity: 0;
            }
            img {
                width: 35px;
                height: 35px;
            }
        }
        &-prev {
            left: 30px;
        }
    }
}
.nav {
    position: absolute;
    top: 50%;
    width: 100%;
}
.rev {
    padding: 85px 0;
    position: relative;
    &-cards {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 106px;
                height: 106px;
                border-radius: 50%;
                object-fit: cover;
            }
            &-box {
                height: 220px;
                .star {
                    width: 120px;
                    height: 20px;
                    display: flex;
                    margin: 0 auto;
                }
                p {
                    text-align: center;
                }
                .nick {
                    line-height: 29px;
                    padding: 9px 0;
                }
                .name {
                    font-size: 22px;
                    line-height: 27px;
                    padding: 8px 0 16px 0;
                }
                .text {
                    line-height: 20px;
                    max-width: 345px;
                }
            }
        }
    }
}
@media (max-width: 970px) {
    .nav {
        // top: 43%;
    }
    .rev {
        padding: 40px 0;
        &-cards {
            flex-wrap: wrap;
            width: 100%;
            &__item {
                width: 100%;
                margin-top: 15px;
                &-box {
                    height: auto;
                }
                img {
                    width: 50px;
                    height: 50px;
                }
                p {
                    font-size: 10px !important;
                    line-height: 12px;
                }
                .name {
                    padding: 10px 0;
                    line-height: 12px;
                }
                .star {
                    width: 70px;
                    object-fit: contain;
                }
                .text {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 7;
                    overflow: hidden;
                    line-height: 17px;
                    width: 70%;
                    margin: 0 auto;
                    text-align: center;
                }
                .nick {
                    padding: 6px 0 10px 0;
                    line-height: 12px;
                }
            }
        }
    }
}
</style>